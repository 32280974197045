import {Action} from 'redux';

export enum ActionType {
  NO_OP = 'NO_OP',
  STARTUP = 'STARTUP',
  SET_DARK_MODE = 'SET_DARK_MODE',
  SET_V2_GAMES_ADMIN_FUNCTIONS = 'SET_V2_GAMES_ADMIN_FUNCTIONS',
  LOGIN_COMPLETE = 'LOGIN_COMPLETE',
  LOG_OUT = 'LOG_OUT',
  LOGIN_ERROR = 'LOGIN_ERROR',
  SET_IS_ADMIN = 'SET_IS_ADMIN',

  FETCH_AMAZON_LOGIN = 'AMAZON_LOGIN',
  FETCH_KUBERNETES_LOGIN = 'KUBERNETES_LOGIN',
  FETCH_KIBANA_LOGIN = 'KIBANA_LOGIN',
  FETCH_AMUNDSEN_LOGIN = 'AMUNDSEN_LOGIN',
  FETCH_GAMES_LOGIN = 'GAMES_LOGIN',
  FETCH_SUPPORT_LOGIN = 'FETCH_SUPPORT_LOGIN',
  ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST',
  ACCOUNT_LOGIN_RESPONSE = 'ACCOUNT_LOGIN_RESPONSE',
  ACCOUNT_LOGIN_ERROR = 'ACCOUNT_LOGIN_ERROR',

  PAGERDUTY_WRITE_INCIDENT_REQUEST = 'PAGERDUTY_WRITE_INCIDENT_REQUEST',
  PAGERDUTY_WRITE_INCIDENT_RESPONSE = 'PAGERDUTY_WRITE_INCIDENT_RESPONSE',
  PAGERDUTY_WRITE_INCIDENT_ERROR = 'PAGERDUTY_WRITE_INCIDENT_ERROR',

  FETCH_PAGERDUTY_STATUS = 'FETCH_PAGERDUTY_STATUS',
  PAGERDUTY_STATUS_REQUEST = 'PAGERDUTY_STATUS_REQUEST',
  PAGERDUTY_STATUS_RESPONSE = 'PAGERDUTY_STATUS_RESPONSE',
  PAGERDUTY_STATUS_ERROR = 'PAGERDUTY_STATUS_ERROR',

  FETCH_PAGERDUTY_WEBHOOK = 'FETCH_PAGERDUTY_WEBHOOK',
  PAGERDUTY_WEBHOOK_REQUEST = 'PAGERDUTY_WEBHOOK_REQUEST',
  PAGERDUTY_WEBHOOK_RESPONSE = 'PAGERDUTY_WEBHOOK_RESPONSE',
  PAGERDUTY_WEBHOOK_ERROR = 'PAGERDUTY_WEBHOOK_ERROR',

  FETCH_FIRESTORE_USER = 'FETCH_FIRESTORE_USER',
  FIRESTORE_USER_REQUEST = 'FIRESTORE_USER_REQUEST',
  FIRESTORE_USER_RESPONSE = 'FIRESTORE_USER_RESPONSE',
  FIRESTORE_USER_ERROR = 'FIRESTORE_USER_ERROR',

  FETCH_FIRESTORE_ACCOUNTS = 'FETCH_FIRESTORE_ACCOUNTS',
  FIRESTORE_ACCOUNTS_REQUEST = 'FIRESTORE_ACCOUNTS_REQUEST',
  FIRESTORE_ACCOUNTS_RESPONSE = 'FIRESTORE_ACCOUNTS_RESPONSE',
  FIRESTORE_ACCOUNTS_ERROR = 'FIRESTORE_ACCOUNTS_ERROR',

  FETCH_FIRESTORE_ROLES = 'FETCH_FIRESTORE_ROLES',
  FIRESTORE_ROLES_REQUEST = 'FIRESTORE_ROLES_REQUEST',
  FIRESTORE_ROLES_RESPONSE = 'FIRESTORE_ROLES_RESPONSE',
  FIRESTORE_ROLES_ERROR = 'FIRESTORE_ROLES_ERROR',

  FETCH_FIRESTORE_USERS = 'FETCH_FIRESTORE_USERS',
  FIRESTORE_USERS_REQUEST = 'FIRESTORE_USERS_REQUEST',
  FIRESTORE_USERS_RESPONSE = 'FIRESTORE_USERS_RESPONSE',
  FIRESTORE_USERS_ERROR = 'FIRESTORE_USERS_ERROR',

  FIRESTORE_WRITE_USER = 'FIRESTORE_WRITE_USER',
  FIRESTORE_DELETE_USER = 'FIRESTORE_DELETE_USER',
}

export interface IAction<P> extends Action<ActionType> {
  type: ActionType;
  payload: P;
}

export const noOpAction = () => ({
  type: ActionType.NO_OP,
  payload: null,
});

export const processErrorToString = (err: any): string => {
  let message: string | undefined = '';
  if (typeof err === 'string') {
    message = err;
  } else if (err instanceof Error) {
    message = err.message;
  } else if (err === undefined) {
    message = 'undefined';
  } else {
    try {
      message = JSON.stringify(err);
    } catch (err) {
      message = `Unable to parse error [${(err as any).message}]`;
    }
  }
  return message;
};
