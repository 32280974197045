import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {IFirestoreAccount} from '../../state/firestore/FirestoreInterfaces';

export const getFirestoreUser = (state: IAppState) =>
  state.serverApis.firestore.user.response;

export const getFirestoreUserError = (state: IAppState) =>
  state.serverApis.firestore.user.error;

export const getIsLoadingAccounts = (state: IAppState) => {
  return (
    state.serverApis.firestore.roles.isUpdating ||
    state.serverApis.firestore.user.isUpdating ||
    state.serverApis.firestore.accounts.isUpdating
  );
};

export const getAccounts = (state: IAppState) => {
  if (state.serverApis.firestore.accounts.response) {
    return filterOutServicesStillInDevelopment(
      state,
      state.serverApis.firestore.accounts.response,
      ['amundsen'],
    );
  }
  return state.serverApis.firestore.accounts.response;
};

export const getAccountsError = (state: IAppState) =>
  state.serverApis.firestore.accounts.error;

export const getRoles = (state: IAppState) =>
  state.serverApis.firestore.roles.response;

export const getRolesError = (state: IAppState) =>
  state.serverApis.firestore.roles.error;

export const getUsers = (state: IAppState) =>
  state.serverApis.firestore.users.response;

export const getUsersError = (state: IAppState) =>
  state.serverApis.firestore.users.error;

export const isDeveloper = createSelector(
  getFirestoreUser,
  (user) =>
    user !== null &&
    (user.id.startsWith('justin.kennedy') ||
      user.id.startsWith('darryl.jennings')),
);

const filterOutServicesStillInDevelopment = (
  state: IAppState,
  accounts: IFirestoreAccount[],
  developmentServices: string[],
): IFirestoreAccount[] => {
  if (state.serverApis.firestore.user.response) {
    if (!isDeveloper(state)) {
      return accounts.filter((account) => {
        return developmentServices.every(
          (service) => service !== account.service,
        );
      });
    }
  }
  return accounts;
};
