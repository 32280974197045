import React from 'react';
import Container from '@material-ui/core/Container';
import {Copyright} from './Copyright';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles({});
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </footer>
  );
};
