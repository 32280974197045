import {Reducer} from 'redux';
import {
  undefinedInReducerError,
  handleServerRequest,
  handleServerResponse,
  handleServerError,
} from '../Reducers';
import {IAction, ActionType} from '../../actions/Actions';
import {IAmazonServerApi} from '../../state/AppState';

export const accountReducer: Reducer<IAmazonServerApi, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.ACCOUNT_LOGIN_REQUEST:
      return {
        ...state,
        login: {
          ...handleServerRequest(state.login, action),
          response: null,
        },
      };
    case ActionType.ACCOUNT_LOGIN_RESPONSE:
      return {
        ...state,
        login: handleServerResponse(state.login, action),
      };
    case ActionType.ACCOUNT_LOGIN_ERROR:
      return {
        ...state,
        login: handleServerError(state.login, action),
      };
  }
  return state;
};
