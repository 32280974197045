import firebase from 'firebase/app';
import React, {useState} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {log} from '../../utils/Log';
import {ErrorSnack} from '../Common/ErrorSnack';
import {getHostedDomain} from '../../selectors/auth/AuthSelectors';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(6, 0, 2),
  },
}));

export const LoginScreen: React.FC = () => {
  const classes = useStyles({});
  const [loginError, setLoginError] = useState<string | null>(null);
  const hostedDomain = getHostedDomain(window.location.host);

  const _startGoogleLogin = async () => {
    log.debug('Starting sign in');

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      hd: hostedDomain,
    });
    try {
      await firebase.auth().signInWithRedirect(provider);
    } catch (err) {
      setLoginError((err as any).message);
    }
  };

  const _clearError = () => {
    setLoginError(null);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Sign in with @{hostedDomain}</Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={_startGoogleLogin}>
          Sign In
        </Button>
        <ErrorSnack message={loginError} onCloseButton={_clearError} />
      </div>
    </Container>
  );
};
