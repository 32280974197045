import {createMuiTheme} from '@material-ui/core';
import {blue, lightBlue} from '@material-ui/core/colors';

const light = createMuiTheme({
  palette: {
    primary: {
      main: '#fb6b2f',
      dark: '#fd5602',
    },
    secondary: blue,
    type: 'light',
  },
});

const dark = createMuiTheme({
  palette: {
    primary: {
      main: '#fb6b2f',
      dark: '#fd5602',
    },
    secondary: lightBlue,
    type: 'dark',
  },
});

export const theme = (isDark: boolean) => (isDark ? dark : light);
