import firebase from 'firebase/app';
import {IAccountLoginDetails} from '../actions/account/AccountActions';
import {
  IFirestoreUser,
  IFirestoreAccount,
  IFirestoreRole,
} from './firestore/FirestoreInterfaces';
import {
  IPagerDutyStatusResponse,
  IPagerDutyWebHookResponse,
  IPagerDutyIncidentResponse,
  IPagerDutyIncidentRequest,
} from './pagerduty/PagerDutyInterfaces';

export const defaultAppState = (): IAppState => ({
  auth: {
    isLogginIn: true,
    user: null,
    isAdmin: false,
  },
  localSettings: {darkMode: true, v2GamesAdminFunctions: false},
  serverApis: {
    account: {
      login: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
    },
    firestore: {
      accounts: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      roles: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      users: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      user: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      pagerDutyWebHook: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
    },
    pagerDuty: {
      status: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
      writeIncident: {
        error: null,
        isUpdating: false,
        lastUpdated: null,
        request: null,
        response: null,
      },
    },
  },
});

export interface IAppState {
  localSettings: ILocalSettings;
  auth: IAuth;
  serverApis: {
    account: IAmazonServerApi;
    firestore: IFirestoreServerApi;
    pagerDuty: IPagerDutyServerApi;
  };
}

export interface ILocalSettings {
  darkMode: boolean;
  v2GamesAdminFunctions: boolean;
}

export interface IAuth {
  isLogginIn: boolean;
  user: firebase.User | null;
  isAdmin: boolean;
}

export interface IAmazonServerApi {
  login: IApiData<IAccountLoginDetails, string, string>;
}

export interface IFirestoreServerApi {
  accounts: IApiData<null, IFirestoreAccount[], string>;
  roles: IApiData<null, IFirestoreRole[], string>;
  users: IApiData<null, IFirestoreUser[], string>;
  user: IApiData<string, IFirestoreUser, string>;
  pagerDutyWebHook: IApiData<null, IPagerDutyWebHookResponse, string>;
}

export interface IPagerDutyServerApi {
  status: IApiData<null, IPagerDutyStatusResponse, string>;
  writeIncident: IApiData<
    IPagerDutyIncidentRequest,
    IPagerDutyIncidentResponse,
    string
  >;
}

export interface IApiData<R, T, E> {
  lastUpdated: Date | null;
  isUpdating: boolean;
  request: R | null;
  response: T | null;
  error: E | null;
}
