import firebase from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {
  map,
  tap,
  filter,
  mergeMap,
  switchMap,
  catchError,
} from 'rxjs/operators';

import {
  accountLoginRequest,
  accountLoginError,
  accountLoginResponse,
} from '../../actions/account/AccountActions';
import {from, of, concat} from 'rxjs';
import {getHostedDomain, getUser} from '../../selectors/auth/AuthSelectors';
import {IAccountLoginDetails} from '../../actions/account/AccountActions';

import {getIsV2GameAdminFunctions} from '../../selectors/localSettings/LocalSettingsSelectors';

export const gamesAdminLoginEpic: Epic<
  IAction<IAccountLoginDetails>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_GAMES_LOGIN),
    switchMap((action) =>
      concat(
        of(accountLoginRequest(action.payload)),
        of(getUser(state$.value)).pipe(
          filter((user): user is firebase.User => user !== null),
          mergeMap((user) =>
            from(user.getIdToken()).pipe(
              map((token) =>
                createGamesAdminUrl(
                  action.payload.accountRole,
                  token,
                  getIsV2GameAdminFunctions(state$.value),
                ),
              ),
              tap((url) => window.open(url, '_blank')),
              map((url) => accountLoginResponse(url)),
            ),
          ),
          catchError((err) =>
            of(err).pipe(map((err) => accountLoginError(err))),
          ),
        ),
      ),
    ),
  );

const createGamesAdminUrl = (
  role: string,
  token: string,
  v2: boolean,
): string => {
  const subdomain = role.substring(0, role.lastIndexOf('_')).replace(/_/g, '-');

  // force sss to v1 becuase of the user lookup issue
  if (subdomain.startsWith('gamesadmin-sss')) {
    v2 = false;
  }

  return `https://${subdomain}${v2 ? '.v2' : ''}.${getHostedDomain(
    window.location.hostname,
  )}/admin/main?token=${token}`;
};
