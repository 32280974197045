import React, {Fragment} from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  Grid,
  Button,
  Hidden,
  Fab,
  Link,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  IPagerDutyAccountModel,
  PagerDutyContactList,
  IPagerDutyUser,
} from '../../../state/pagerduty/PagerDutyInterfaces';
import {getColorForStatus} from '../../../selectors/pagerduty/PagerDutySelectors';

interface IProps {
  open: boolean;
  account: IPagerDutyAccountModel;
  onCreateIncident: (service: {id: string; name: string}) => void;
}

const useStyles = makeStyles((theme) => ({
  collapse: {
    position: 'relative',
  },
  cards: {
    position: 'relative',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
    cursor: 'default',
    margin: theme.spacing(1),
    backgroundColor: `rgba(255,255,255,0.07)`,
  },
  listItemHeading: {
    fontWeight: 500,
    paddingTop: 16,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  listItem: {
    paddingTop: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  userContacts: {
    display: 'inline',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingRight: 0,
    },
  },
  fabContainer: {
    right: 20,
    top: 20,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fabDescription: {
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
  fab: {},
  addIcon: {
    color: theme.palette.background.default,
  },
}));

export const PagerDutyDetails: React.FC<IProps> = (props) => {
  const classes = useStyles({} as any);
  const {account: service} = props;

  return (
    <Collapse
      className={classes.collapse}
      in={props.open}
      timeout="auto"
      unmountOnExit>
      {service.incidents.length > 0 &&
        service.incidents.map((incident) => (
          <Card
            className={classes.cards}
            key={incident.incident.id}
            style={{
              borderColor: getColorForStatus(service.status),
              borderWidth: '4px',
              borderStyle: 'solid',
            }}>
            <CardHeader
              title={incident.incident.title}
              subheader={incident.incident.summary}
            />
            <CardContent>
              {incident.incident.description}
              <List>
                {incident.assignments.map((user, index) => (
                  <PagerDutyUser key={user.id} user={user} index={index} />
                ))}
              </List>
            </CardContent>
          </Card>
        ))}
      <Card className={classes.cards}>
        <CardHeader
          title="Current on call"
          subheader="Listed by escalation level"
        />
        <CardContent>
          <List>
            {service.users.map((user, index) => (
              <PagerDutyUser key={user.id} user={user} index={index} />
            ))}
          </List>
        </CardContent>
        <Grid className={classes.fabContainer}>
          <Typography className={classes.fabDescription}>
            Notify on call team members
          </Typography>
          <Tooltip
            title={`Report incident for '${props.account.service.name}'`}
            placement="left-end">
            <Fragment>
              <Hidden xsDown>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    props.onCreateIncident({
                      id: service.service.id,
                      name: service.service.name,
                    })
                  }>
                  Report Incident
                </Button>
              </Hidden>
              <Hidden smUp>
                <Fab
                  size="medium"
                  color="primary"
                  aria-label="add"
                  className={classes.fab}
                  onClick={() =>
                    props.onCreateIncident({
                      id: service.service.id,
                      name: service.service.name,
                    })
                  }>
                  <AddIcon className={classes.addIcon} />
                </Fab>
              </Hidden>
            </Fragment>
          </Tooltip>
        </Grid>
      </Card>
    </Collapse>
  );
};

interface IPagerDutyUserProps {
  user: IPagerDutyUser;
  index: number;
}

const PagerDutyUser: React.FC<IPagerDutyUserProps> = (props) => {
  const classes = useStyles({} as any);
  const {user} = props;
  return (
    <Fragment>
      <ListItem className={classes.listItemHeading}>{`${_numberWithSuffix(
        props.index + 1,
      )} Contact`}</ListItem>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar src={user.avatar_url}></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={
            <Fragment>
              <Typography component="span" className={classes.userContacts}>
                {user.email}
              </Typography>
              <Typography component="span" className={classes.userContacts}>
                {_getContactPhone(user.contact_methods)}
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
    </Fragment>
  );
};

const _getContactPhone = (
  contacts: PagerDutyContactList,
): JSX.Element | null => {
  for (const contact of contacts) {
    if (
      contact.type === 'sms_contact_method' ||
      contact.type === 'phone_contact_method'
    ) {
      return (
        <Link
          href={`tel:+${contact.country_code}${contact.address}`}
          color="secondary">
          {`(+${contact.country_code}) ${contact.address}`}
        </Link>
      );
    }
  }
  return null;
};

const _numberWithSuffix = (n: number): string => {
  switch (n) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    default:
      return `${n}th`;
  }
};
