import {combineEpics} from 'redux-observable';

import {startupLoginEpic} from './auth/AuthEpics';
import {
  readLocalSettingsEpic,
  setDarkModeEpic,
  set2GamesAdminFunctionsEpic,
} from './localSettings/LocalSettingsEpics';
import {initFirebaseEpic} from './firebase/FirebaseEpics';
import {
  fetchAccountsEpic,
  fetchUserEpic,
  fetchRolesEpic,
  fetchUsersEpic,
  writeFirestoreUserEpic,
  deleteFirestoreUserEpic,
} from './firestore/FirestoreEpics';
import {amazonAccountLoginEpic} from './amazon/AmazonEpics';
import {kubernetesAccountLoginEpic} from './kubernetes/KubernetesEpics';
import {amundsenAccountLoginEpic} from './amundsen/AmundsenEpics';
import {kibanaAccountLoginEpic} from './kibana/KibanaEpics';
import {gamesAdminLoginEpic} from './games/GamesEpics';
import {
  pagerDutyStatusEpic,
  pagerDutyWebhookEpic,
  writePagerDutyIncidentEpic,
} from './pagerduty/PagerDutyEpics';
import {supportLoginEpic} from './support/SupportEpics';

export const rootEpic = combineEpics(
  initFirebaseEpic,
  startupLoginEpic,
  fetchUserEpic,
  fetchAccountsEpic,
  fetchRolesEpic,
  fetchUsersEpic,
  writeFirestoreUserEpic,
  deleteFirestoreUserEpic,
  readLocalSettingsEpic,
  setDarkModeEpic,
  set2GamesAdminFunctionsEpic,
  amazonAccountLoginEpic,
  kubernetesAccountLoginEpic,
  amundsenAccountLoginEpic,
  kibanaAccountLoginEpic,
  gamesAdminLoginEpic,
  supportLoginEpic,
  pagerDutyStatusEpic,
  pagerDutyWebhookEpic,
  writePagerDutyIncidentEpic,
);
