export enum MenuItem {
  ON_CALL = 'On Call',
  AWS = 'AWS',
  GAMES = 'Games Admin',
  SUPPORT = 'Support',
  AMUNDSEN = 'Amundsen',
  KUBERNETES = 'Kubernetes',
  KIBANA = 'Kibana',
  ADMINISTRATION = 'Administration',
}

export const menuRoutes: {[key in MenuItem]: string} = {
  [MenuItem.AWS]: '/aws',
  [MenuItem.KUBERNETES]: '/kube',
  [MenuItem.KIBANA]: '/kib',
  [MenuItem.AMUNDSEN]: '/amundsen',
  [MenuItem.ON_CALL]: '/oncall',
  [MenuItem.GAMES]: '/gamesadmin',
  [MenuItem.SUPPORT]: '/support',
  [MenuItem.ADMINISTRATION]: '/administration',
};

export const getMenuItemFromPath = (
  path: string | undefined,
): MenuItem | null => {
  if (!path) {
    return null;
  }
  const routes = menuRoutes;
  let item = null;
  Object.keys(routes).forEach((key) => {
    if (routes[key as MenuItem] === `${path}`) {
      item = key;
    }
  });
  return item;
};
