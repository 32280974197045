import firebase, {firestore} from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {from, of, concat, Subject} from 'rxjs';
import {map, tap, filter, switchMap, catchError} from 'rxjs/operators';
import {ajax, AjaxRequest} from 'rxjs/ajax';

import {
  pagerDutyStatusRequest,
  pagerDutyStatusError,
  pagerDutyStatusResponse,
  pagerDutyWebhookRequest,
  pagerDutyWebhookResponse,
  pagerDutyWebhookError,
  fetchPagerDutyStatus,
  pagerDutyWriteIncidentResponse,
  pagerDutyWriteIncidentError,
} from '../../actions/pagerduty/PagerDutyActions';
import {getUser} from '../../selectors/auth/AuthSelectors';
import {getIsPagerDutyStatusUpdating} from '../../selectors/pagerduty/PagerDutySelectors';
import {
  IPagerDutyStatusResponse,
  IPagerDutyWebHookResponse,
  IPagerDutyIncidentRequest,
  IPagerDutyIncidentResponse,
} from '../../state/pagerduty/PagerDutyInterfaces';
import log from '../../utils/Log';

const db = () => firebase.firestore();

export const pagerDutyStatusEpic: Epic<
  IAction<any>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(
      ActionType.FETCH_PAGERDUTY_STATUS,
      ActionType.PAGERDUTY_WRITE_INCIDENT_RESPONSE,
    ),
    switchMap((action) =>
      of(getUser(state$.value)).pipe(
        filter((user): user is firebase.User => user !== null),
        switchMap((user) =>
          concat(
            of(pagerDutyStatusRequest()),
            from(user.getIdToken()).pipe(
              switchMap((token) =>
                ajax(createGetPagerDutyStatusRequest(token)).pipe(
                  tap((response) => log.info(response)),
                  map((response) =>
                    pagerDutyStatusResponse(
                      response.response as IPagerDutyStatusResponse,
                    ),
                  ),
                ),
              ),
              catchError((err) =>
                of(err).pipe(map((err) => pagerDutyStatusError(err))),
              ),
            ),
          ),
        ),
      ),
    ),
  );

const createGetPagerDutyStatusRequest = (token: string): AjaxRequest => ({
  url: `https://us-central1-authenticator-253222.cloudfunctions.net/getPagerDutyStatus`,
  // url: `https://${getAuthDomain(
  //   window.location.hostname,
  // )}/amazon?accountRole=${role}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'GET',
  responseType: 'json',
});

// Subscribe to the webhook response document.
// The `onPagerDutyChange` cloudfunction is called by the pagerduty webhook when updates occur in the system. The cloud function updates this document with the latest response.
// We use the subscription to this doc to trigger re-requests of the latest status data.
export const pagerDutyWebhookEpic: Epic<
  IAction<any>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_PAGERDUTY_WEBHOOK),
    switchMap(() =>
      concat(
        of(pagerDutyWebhookRequest()),
        createWebhookResponseObserver().pipe(
          map((docSnap) => docSnap.data() as IPagerDutyWebHookResponse),
          switchMap((webhookData) =>
            concat(
              of('').pipe(map(() => pagerDutyWebhookResponse(webhookData))),
              of('').pipe(
                filter(() => !getIsPagerDutyStatusUpdating(state$.value)),
                map(() => fetchPagerDutyStatus()),
              ),
            ),
          ),
        ),
        of('').pipe(
          tap(() => log.debug('PagerDutyWebhook observer complete')),
          map(() => pagerDutyWebhookError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(pagerDutyWebhookError(err)))),
    ),
  );

const createWebhookResponseObserver = () => {
  const subject = new Subject<firestore.DocumentSnapshot>();
  db()
    .collection('subscriptions')
    .doc('pagerduty')
    .onSnapshot(subject);
  return subject;
};

export const writePagerDutyIncidentEpic: Epic<
  IAction<any>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.PAGERDUTY_WRITE_INCIDENT_REQUEST),
    map((action) => action.payload as IPagerDutyIncidentRequest),
    switchMap((payload) =>
      of(getUser(state$.value)).pipe(
        filter((user): user is firebase.User => user !== null),
        switchMap((user) =>
          from(user.getIdToken()).pipe(
            switchMap((token) =>
              ajax(createWritePagerDutyIncidentRequest(token, payload)).pipe(
                tap((response) => log.info(response)),
                map((response) =>
                  pagerDutyWriteIncidentResponse(
                    response.response as IPagerDutyIncidentResponse,
                  ),
                ),
              ),
            ),
            catchError((err) =>
              of(err).pipe(map((err) => pagerDutyWriteIncidentError(err))),
            ),
          ),
        ),
      ),
    ),
  );

const createWritePagerDutyIncidentRequest = (
  token: string,
  request: IPagerDutyIncidentRequest,
): AjaxRequest => ({
  url: `https://us-central1-authenticator-253222.cloudfunctions.net/createPagerDutyIncident`,
  body: request,
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  method: 'POST',
  responseType: 'json',
});
