import {IAction, ActionType, processErrorToString} from '../Actions';

export interface IAccountLoginDetails {
  accountName: string;
  accountRole: string;
}

export const fetchKubernetesAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_KUBERNETES_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const fetchKibanaAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_KIBANA_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const fetchAmundsenAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_AMUNDSEN_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const fetchGamesAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_GAMES_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const fetchSupportAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_SUPPORT_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const fetchAmazonAccountLogin = (
  accountName: string,
  accountRole: string,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.FETCH_AMAZON_LOGIN,
  payload: {
    accountName: accountName,
    accountRole: accountRole,
  },
});

export const accountLoginRequest = (
  account: IAccountLoginDetails,
): IAction<IAccountLoginDetails> => ({
  type: ActionType.ACCOUNT_LOGIN_REQUEST,
  payload: account,
});

export const accountLoginResponse = (url: string): IAction<string> => ({
  type: ActionType.ACCOUNT_LOGIN_RESPONSE,
  payload: url,
});

export const accountLoginError = (err: any): IAction<string> => {
  return {
    type: ActionType.ACCOUNT_LOGIN_ERROR,
    payload: processErrorToString(err),
  };
};
