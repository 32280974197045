import {Reducer} from 'redux';
import {undefinedInReducerError} from '../Reducers';
import {IAction, ActionType} from '../../actions/Actions';
import {ILocalSettings} from '../../state/AppState';

export const localSettingsReducer: Reducer<ILocalSettings, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_DARK_MODE:
      return {...state, darkMode: action.payload};
    case ActionType.SET_V2_GAMES_ADMIN_FUNCTIONS:
      return {...state, v2GamesAdminFunctions: action.payload};
  }
  return state;
};
