import firebase from 'firebase/app';
import React, {useState, useEffect} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import {ErrorSnack} from '../Common/ErrorSnack';
import {ModalProgress} from '../Common/ModalProgress';
import {AccountList} from './AccountList';
import {PagerDutyAccountList} from '../PagerDuty/PagerDutyAccountList';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchAmazonAccountLogin,
  fetchKubernetesAccountLogin,
  fetchKibanaAccountLogin,
  fetchAmundsenAccountLogin,
  fetchGamesAccountLogin,
  fetchSupportAccountLogin,
} from '../../actions/account/AccountActions';
import {
  getCurrentAccountLoginName,
  getCurrentAccountLoginError,
  getIsLoggingIntoAccount,
} from '../../selectors/account/AccountSelectors';
import {getIsLoadingAccounts} from '../../selectors/firestore/FirestoreSelectors';
import {
  IServiceAccount,
  getAmazonScreenAccounts,
  getWelcomeScreenError,
  getKubernetesScreenAccounts,
  getKibanaScreenAccounts,
  getGamesScreenAccounts,
  getAmundsenScreenAccounts,
  getSupportScreenAccounts,
} from '../../selectors/welcome/WelcomeSelectors';
import {MenuItem} from '../Routes';
import {getUser} from '../../selectors/auth/AuthSelectors';
import {setV2GamesAdminFunctions} from '../../actions/localSettings/LocalSettingsActions';
import {getIsV2GameAdminFunctions} from '../../selectors/localSettings/LocalSettingsSelectors';
import {Switch, FormControlLabel} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface IComponentProps {
  screen: MenuItem;
}

interface IStateProps {
  screen: MenuItem | null;
  user: firebase.User | null;
  amazonAccountsList: IServiceAccount[];
  amundsenAccountsList: IServiceAccount[];
  kibanaAccountsList: IServiceAccount[];
  kubernetesAccountsList: IServiceAccount[];
  gamesAccountsList: IServiceAccount[];
  supportAccountsList: IServiceAccount[];
  accountsListError: string | null;
  isRequestingAccounts: boolean;
  isLoggingIn: boolean;
  loggingIntoAccount: string | null;
  loggingIntoAccountError: string | null;
  showV2Toggle: boolean;
  isV2Selected: boolean;
}

interface IDispatchProps {
  doAccountLogin: (
    accountName: string,
    accountId: string,
    accountRole: string,
  ) => void;
  setV2Selected: (selected: boolean) => void;
}

export const WelcomeScreen: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <WelcomeScreenView
      {...{
        ...props,
        user: useSelector(getUser),
        amazonAccountsList: useSelector(getAmazonScreenAccounts),
        amundsenAccountsList: useSelector(getAmundsenScreenAccounts),
        kubernetesAccountsList: useSelector(getKubernetesScreenAccounts),
        kibanaAccountsList: useSelector(getKibanaScreenAccounts),
        gamesAccountsList: useSelector(getGamesScreenAccounts),
        supportAccountsList: useSelector(getSupportScreenAccounts),
        accountsListError: useSelector(getWelcomeScreenError),
        isRequestingAccounts: useSelector(getIsLoadingAccounts),
        isLoggingIn: useSelector(getIsLoggingIntoAccount),
        loggingIntoAccount: useSelector(getCurrentAccountLoginName),
        loggingIntoAccountError: useSelector(getCurrentAccountLoginError),
        showV2Toggle: false, //props.screen === MenuItem.GAMES,
        isV2Selected: useSelector(getIsV2GameAdminFunctions),
        doAccountLogin: (
          accountName: string,
          accountId: string,
          accountRole: string,
        ) => {
          if (props.screen === MenuItem.AWS) {
            dispatch(fetchAmazonAccountLogin(accountName, accountRole));
          } else if (props.screen === MenuItem.KUBERNETES) {
            dispatch(fetchKubernetesAccountLogin(accountName, accountRole));
          } else if (props.screen === MenuItem.KIBANA) {
            dispatch(fetchKibanaAccountLogin(accountName, accountRole));
          } else if (props.screen === MenuItem.AMUNDSEN) {
            dispatch(fetchAmundsenAccountLogin(accountName, accountRole));
          } else if (props.screen === MenuItem.GAMES) {
            dispatch(fetchGamesAccountLogin(accountName, accountRole));
          } else if (props.screen === MenuItem.SUPPORT) {
            dispatch(fetchSupportAccountLogin(accountName, accountRole));
          }
        },
        setV2Selected: (selected: boolean) => {
          if (props.screen === MenuItem.GAMES) {
            dispatch(setV2GamesAdminFunctions(selected));
          }
        },
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  prompt: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accountPaper: {
    marginTop: theme.spacing(1),
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  noAccess: {
    marginTop: theme.spacing(8),
  },
}));

export const WelcomeScreenView: React.FC<
  IComponentProps & IStateProps & IDispatchProps
> = (props) => {
  const classes = useStyles({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (props.loggingIntoAccountError !== null) {
      setShowErrorModal(true);
    }
  }, [props.loggingIntoAccountError]);

  const getAccountsForScreen = (screen: MenuItem) => {
    if (screen === MenuItem.AWS) {
      return props.amazonAccountsList;
    } else if (screen === MenuItem.AMUNDSEN) {
      return props.amundsenAccountsList;
    } else if (screen === MenuItem.KUBERNETES) {
      return props.kubernetesAccountsList;
    } else if (screen === MenuItem.KIBANA) {
      return props.kibanaAccountsList;
    } else if (screen === MenuItem.GAMES) {
      return props.gamesAccountsList;
    } else if (screen === MenuItem.SUPPORT) {
      return props.supportAccountsList;
    } else {
      return [];
    }
  };

  let content: JSX.Element;
  if (props.accountsListError !== null) {
    // No accounts
    content = (
      <Typography className={classes.noAccess} align="center">
        {props.accountsListError}
      </Typography>
    );
  } else if (props.screen === MenuItem.ON_CALL) {
    content = <PagerDutyAccountList />;
  } else if (
    !props.isLoggingIn &&
    !props.isRequestingAccounts &&
    getAccountsForScreen(props.screen).length === 0
  ) {
    content = (
      <Typography className={classes.noAccess} align="center">
        You have not been granted access to any of the services in this section.
        <br />
        Please contact a server administrator to request access.
      </Typography>
    );
  } else if (!props.isRequestingAccounts) {
    content = (
      <Paper className={classes.accountPaper}>
        {props.screen !== null && (
          <AccountList
            service={props.screen}
            accounts={getAccountsForScreen(props.screen)}
            onSelected={props.doAccountLogin}
          />
        )}
      </Paper>
    );
  } else {
    content = <div />;
  }

  const _onV2Selected = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    props.setV2Selected(checked);
  };

  return (
    <Container component="main" maxWidth="xl">
      {props.showV2Toggle && (
        <div className={classes.prompt}>
          <Alert variant="outlined" icon={false} severity="warning">
            <FormControlLabel
              control={
                <Switch
                  checked={props.isV2Selected}
                  onChange={_onV2Selected}
                  color="primary"
                />
              }
              label="Use experimental V2 Games Admin (should improve page load times)"
              labelPlacement="start"
            />
          </Alert>
        </div>
      )}
      <div className={classes.paper}>{content}</div>
      <ModalProgress
        open={
          props.isLoggingIn === true &&
          props.loggingIntoAccount !== null &&
          props.loggingIntoAccountError === null
        }
        label={`Connecting to ${props.loggingIntoAccount}`}
      />
      <ErrorSnack
        message={showErrorModal ? props.loggingIntoAccountError : null}
        onCloseButton={() => setShowErrorModal(false)}
      />
    </Container>
  );
};
