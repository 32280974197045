import firebase from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType, noOpAction} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {ajax, AjaxRequest, AjaxResponse} from 'rxjs/ajax';
import {
  map,
  tap,
  filter,
  mergeMap,
  switchMap,
  catchError,
} from 'rxjs/operators';

import {
  IAccountLoginDetails,
  accountLoginError,
  accountLoginRequest,
  accountLoginResponse,
} from '../../actions/account/AccountActions';
import {from, of, merge, concat} from 'rxjs';
import {getUser, getAuthDomain} from '../../selectors/auth/AuthSelectors';
import log from '../../utils/Log';

export const amazonAccountLoginEpic: Epic<
  IAction<IAccountLoginDetails>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_AMAZON_LOGIN),
    switchMap((action) =>
      concat(
        of(accountLoginRequest(action.payload)),
        of(getUser(state$.value)).pipe(
          filter((user): user is firebase.User => user !== null),
          mergeMap((user) =>
            from(user.getIdToken()).pipe(
              mergeMap((token) =>
                merge(
                  ajax(
                    createGetAWSConsoleUrlRequest(
                      action.payload.accountRole,
                      token,
                    ),
                  ).pipe(
                    map((response) => getAWSConsoleUrlFromResponse(response)),
                    mergeMap((url) =>
                      concat(
                        of(accountLoginResponse(url)),
                        of(url).pipe(
                          tap((url) => log.info('AWS Url: ', url)),
                          tap((url) => location.assign(url)),
                          map(() => noOpAction()),
                        ),
                      ),
                    ),
                  ),
                  from(logout()).pipe(
                    tap(() => log.info('Logout complete')),
                    map(() => noOpAction()),
                  ),
                ),
              ),
            ),
          ),
          catchError((err) =>
            of(err).pipe(map((err) => accountLoginError(err))),
          ),
        ),
      ),
    ),
  );

const createGetAWSConsoleUrlRequest = (
  role: string,
  token: string,
): AjaxRequest => ({
  url: `https://${getAuthDomain(
    window.location.hostname,
  )}/amazon?accountRole=${role}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'GET',
  responseType: 'text',
});

const getAWSConsoleUrlFromResponse = (response: AjaxResponse): string => {
  if (response.response && typeof response.response === 'string') {
    return response.response;
  } else {
    throw new Error('Unexpected response format from getAWSConsoleUrl');
  }
};

const logout = () =>
  new Promise<null>((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.onload = () => {
      document.body.removeChild(iframe);
      resolve(null);
    };
    iframe.src = `https://console.aws.amazon.com/console/logout!doLogout`;
    document.body.appendChild(iframe);
  });
