import {Reducer} from 'redux';
import {
  undefinedInReducerError,
  handleServerRequest,
  handleServerResponse,
  handleServerError,
} from '../Reducers';
import {IAction, ActionType} from '../../actions/Actions';
import {IPagerDutyServerApi} from '../../state/AppState';

export const pagerDutyReducer: Reducer<IPagerDutyServerApi, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.PAGERDUTY_STATUS_REQUEST:
      return {
        ...state,
        status: handleServerRequest(state.status, action),
      };
    case ActionType.PAGERDUTY_STATUS_RESPONSE:
      return {
        ...state,
        status: handleServerResponse(state.status, action),
      };
    case ActionType.PAGERDUTY_STATUS_ERROR:
      return {
        ...state,
        status: handleServerError(state.status, action),
      };

    case ActionType.PAGERDUTY_WRITE_INCIDENT_REQUEST:
      return {
        ...state,
        writeIncident: handleServerRequest(state.writeIncident, action),
      };
    case ActionType.PAGERDUTY_WRITE_INCIDENT_RESPONSE:
      return {
        ...state,
        writeIncident: handleServerResponse(state.writeIncident, action),
      };
    case ActionType.PAGERDUTY_WRITE_INCIDENT_ERROR:
      return {
        ...state,
        writeIncident: handleServerError(state.writeIncident, action),
      };
  }
  return state;
};
