import React from 'react';
import {theme} from './Theme';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {ReactNode} from 'react';

interface IProps {
  isDark: boolean;
  children: ReactNode;
}

export const ThemeProvider: React.FC<IProps> = (props: IProps) => {
  return (
    <MuiThemeProvider theme={theme(props.isDark)}>
      {props.children}
    </MuiThemeProvider>
  );
};
