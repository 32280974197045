import React from 'react';

import {makeStyles, Hidden} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import {capFirstCharacter} from '../../../utils/TextUtils';

import {
  IPagerDutyAccountModel,
  PagerDutyStatus,
} from '../../../state/pagerduty/PagerDutyInterfaces';
import {getColorForStatus} from '../../../selectors/pagerduty/PagerDutySelectors';

interface IProps {
  account: IPagerDutyAccountModel;
  open: boolean;
  onOpen: (id: string) => void;
}

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    border: 1,
  },
}));

export const PagerDutyHeader: React.FC<IProps> = (props) => {
  const classes = useStyles({} as any);
  const {account: service} = props;
  return (
    <ListItem
      button
      className={classes.categoryItem}
      onClick={() => props.onOpen(props.account.service.id)}>
      <ListItemIcon>
        <StatusIndicator status={service.status} />
      </ListItemIcon>
      <ListItemText primary={capFirstCharacter(service.service.name)} />
      {props.open !== true ? <ExpandMore /> : <ExpandLess />}
    </ListItem>
  );
};

interface IStatusIndicatorProps {
  status: PagerDutyStatus;
}

const useStylesStatus = makeStyles((theme) => ({
  chip: {
    marginRight: '16px',
    fontWeight: 600,
    color: 'white',
  },
  chipIcon: {
    marginLeft: 4,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
    },
    color: 'white',
  },
}));
export const StatusIndicator: React.FC<IStatusIndicatorProps> = (props) => {
  const classes = useStylesStatus({} as any);

  if (props.status === 'triggered') {
    return (
      <Chip
        size="small"
        icon={<ReportProblemIcon className={classes.chipIcon} />}
        label={<Hidden xsDown>INCIDENT</Hidden>}
        className={classes.chip}
        style={{backgroundColor: getColorForStatus(props.status)}}
      />
    );
  } else if (props.status === 'acknowledged') {
    return (
      <Chip
        size="small"
        icon={<ReportProblemIcon className={classes.chipIcon} />}
        label={<Hidden xsDown>ACKNOWLEDGED</Hidden>}
        className={classes.chip}
        style={{backgroundColor: getColorForStatus(props.status)}}
      />
    );
  }
  return (
    <Chip
      size="small"
      icon={<CheckCircleIcon className={classes.chipIcon} />}
      label={<Hidden xsDown>HEALTHY</Hidden>}
      className={classes.chip}
      style={{backgroundColor: getColorForStatus(props.status)}}
    />
  );
};
