import {IAction, ActionType, processErrorToString} from '../Actions';
import {
  IFirestoreUser,
  IFirestoreAccount,
  IFirestoreRole,
} from '../../state/firestore/FirestoreInterfaces';

export const fetchFirestoreUser = (email: string): IAction<string> => ({
  payload: email,
  type: ActionType.FETCH_FIRESTORE_USER,
});

export const firestoreUserRequest = (email: string): IAction<string> => ({
  payload: email,
  type: ActionType.FIRESTORE_USER_REQUEST,
});

export const firestoreUserResponse = (
  user: IFirestoreUser,
): IAction<IFirestoreUser> => ({
  payload: user,
  type: ActionType.FIRESTORE_USER_RESPONSE,
});

export const firestoreUserError = (err: any): IAction<string> => ({
  payload: processErrorToString(err),
  type: ActionType.FIRESTORE_USER_ERROR,
});

export const fetchFirestoreAccounts = (): IAction<null> => ({
  payload: null,
  type: ActionType.FETCH_FIRESTORE_ACCOUNTS,
});

export const firestoreAccountsRequest = (): IAction<null> => ({
  payload: null,
  type: ActionType.FIRESTORE_ACCOUNTS_REQUEST,
});

export const firestoreAccountsResponse = (
  accounts: IFirestoreAccount[],
): IAction<IFirestoreAccount[]> => ({
  payload: accounts,
  type: ActionType.FIRESTORE_ACCOUNTS_RESPONSE,
});

export const firestoreAccountsError = (err: any): IAction<string> => ({
  payload: processErrorToString(err),
  type: ActionType.FIRESTORE_ACCOUNTS_ERROR,
});

export const fetchFirestoreRoles = (): IAction<null> => ({
  payload: null,
  type: ActionType.FETCH_FIRESTORE_ROLES,
});

export const firestoreRolesRequest = (): IAction<null> => ({
  payload: null,
  type: ActionType.FIRESTORE_ROLES_REQUEST,
});

export const firestoreRolesResponse = (
  accounts: IFirestoreRole[],
): IAction<IFirestoreRole[]> => ({
  payload: accounts,
  type: ActionType.FIRESTORE_ROLES_RESPONSE,
});

export const firestoreRolesError = (err: any): IAction<string> => ({
  payload: processErrorToString(err),
  type: ActionType.FIRESTORE_ROLES_ERROR,
});

export const fetchFirestoreUsers = (): IAction<null> => ({
  payload: null,
  type: ActionType.FETCH_FIRESTORE_USERS,
});

export const firestoreUsersRequest = (): IAction<null> => ({
  payload: null,
  type: ActionType.FIRESTORE_USERS_REQUEST,
});

export const firestoreUsersResponse = (
  accounts: IFirestoreUser[],
): IAction<IFirestoreUser[]> => ({
  payload: accounts,
  type: ActionType.FIRESTORE_USERS_RESPONSE,
});

export const firestoreUsersError = (err: any): IAction<string> => ({
  payload: processErrorToString(err),
  type: ActionType.FIRESTORE_USERS_ERROR,
});

export const writeFirestoreUser = (
  user: IFirestoreUser,
): IAction<IFirestoreUser> => ({
  payload: user,
  type: ActionType.FIRESTORE_WRITE_USER,
});

export const deleteFirestoreUser = (email: string): IAction<string> => ({
  payload: email,
  type: ActionType.FIRESTORE_DELETE_USER,
});
