import firebase from 'firebase/app';
import {IAction, ActionType} from '../Actions';

export const loginComplete = (user: firebase.User): IAction<firebase.User> => ({
  payload: user,
  type: ActionType.LOGIN_COMPLETE,
});

export const logOut = (): IAction<null> => ({
  payload: null,
  type: ActionType.LOG_OUT,
});

export const loginError = (error: any): IAction<any> => ({
  payload: error,
  type: ActionType.LOGIN_ERROR,
});

export const setIsAdmin = (isAdmin: boolean): IAction<boolean> => ({
  payload: isAdmin,
  type: ActionType.SET_IS_ADMIN,
});
