import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '35%',
    left: '50%',
    position: 'absolute',
    width: 400,
    marginLeft: -200,
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  textField: {
    width: '100%',
    margin: theme.spacing(2, 2, 1, 0),
  },
  buttonProcessing: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
}));

interface IProps {
  service: {id: string; name: string} | null;
  onCancel: () => void;
  onConfirmed: (service: string, email: string, details: string) => void;
}

export const ReportIncidentOverlay: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  const [processing, setProcessing] = useState(false);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');

  const _onTitleChanged = (event: any) => {
    setTitle(event.target.value);
  };

  const _onDetailsChanged = (event: any) => {
    setDetails(event.target.value);
  };

  const _isFormDataValid = (title: string, details: string) => {
    // Doesn't contain @ with atleast one character before it.
    if (title !== '' && details !== '') {
      return true;
    }

    return false;
  };

  const _onConfirmed = (email: string, details: string) => {
    if (props.service !== null) {
      setProcessing(true);
      props.onConfirmed(props.service.id, email, details);
    }
  };

  return (
    <div>
      {props.service !== null && (
        <Modal
          aria-labelledby="add-user-modal-title"
          aria-describedby="add-user-modal-description"
          open={props.service !== null}>
          <div className={classes.modal}>
            <Typography variant="h6">
              {`Report an incident for ${props.service.name}`}
            </Typography>
            <Typography className={classes.textField}>
              On call team members will be notified using their configured
              communication platforms.
            </Typography>
            <TextField
              required
              disabled={processing}
              id="title-required"
              label="Title"
              value={title}
              onChange={_onTitleChanged}
              className={classes.textField}
              margin="normal"
              color="primary"
              variant="outlined"
            />
            <TextField
              required
              disabled={processing}
              id="description-required"
              label="Description"
              value={details}
              onChange={_onDetailsChanged}
              className={classes.textField}
              margin="normal"
              color="primary"
              variant="outlined"
              multiline
              rows={5}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                aria-label="cancel"
                color="default"
                variant="contained"
                onClick={props.onCancel}>
                Close
              </Button>
              <Button
                className={classes.button}
                aria-label="add"
                color="primary"
                variant="contained"
                disabled={!_isFormDataValid(title, details) || processing}
                onClick={() => _onConfirmed(title, details)}>
                Report
              </Button>
            </div>
            {processing && (
              <CircularProgress className={classes.buttonProcessing} />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
