import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType, noOpAction} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {map, tap, filter, mergeMap} from 'rxjs/operators';
import {
  setDarkMode,
  setV2GamesAdminFunctions,
} from '../../actions/localSettings/LocalSettingsActions';
import {concat, of} from 'rxjs';

export const readLocalSettingsEpic: Epic<
  IAction<any>,
  IAction<boolean>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    mergeMap(() =>
      concat(
        of(localStorage.getItem('isDark') !== 'false').pipe(
          map((isDark) => setDarkMode(isDark)),
        ),
        of(localStorage.getItem('isv2GamesAdminFunctions') === 'true').pipe(
          map((isV2) => setV2GamesAdminFunctions(isV2)),
        ),
      ),
    ),
  );

export const setDarkModeEpic: Epic<IAction<any>, IAction<null>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.SET_DARK_MODE),
    tap((action) =>
      localStorage.setItem('isDark', JSON.stringify(action.payload)),
    ),
    filter(() => false),
    map(() => noOpAction()),
  );

export const set2GamesAdminFunctionsEpic: Epic<
  IAction<any>,
  IAction<null>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_V2_GAMES_ADMIN_FUNCTIONS),
    tap((action) =>
      localStorage.setItem(
        'isv2GamesAdminFunctions',
        JSON.stringify(action.payload),
      ),
    ),
    filter(() => false),
    map(() => noOpAction()),
  );
