import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  message: string | null;
  onCloseButton: () => void;
}

export const ErrorSnack: React.FC<IProps> = (props: IProps) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.message !== null}
      autoHideDuration={6000}
      onClose={props.onCloseButton}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{props.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={props.onCloseButton}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
