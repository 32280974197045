import firebase from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType, noOpAction} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {map, tap, filter} from 'rxjs/operators';

import {getAuthDomain} from '../../selectors/auth/AuthSelectors';

export const initFirebaseEpic: Epic<IAction<any>, IAction<null>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    map(() => ({
      apiKey: 'AIzaSyC-j-ZFVq55aqs01uKwJlPZ4z6_bsWm2RM',
      authDomain: getAuthDomain(window.location.hostname),
      databaseURL: 'https://authenticator-253222.firebaseio.com',
      projectId: 'authenticator-253222',
      storageBucket: '',
      messagingSenderId: '625930586335',
      appId: '1:625930586335:web:e8b266b05d25e123d0016a',
    })),
    tap((config) => firebase.initializeApp(config)),
    filter(() => false),
    map(() => noOpAction()),
  );
