import {IAppState} from '../../state/AppState';

export const getCurrentAccountLoginName = (state: IAppState) => {
  if (state.serverApis.account.login.request !== null) {
    return state.serverApis.account.login.request.accountName;
  } else {
    return null;
  }
};

export const getIsLoggingIntoAccount = (state: IAppState) =>
  state.serverApis.account.login.isUpdating;

export const getCurrentAccountLoginResponse = (state: IAppState) =>
  state.serverApis.account.login.response;

export const getCurrentAccountLoginError = (state: IAppState) =>
  state.serverApis.account.login.error;
