import React from 'react';

import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import {createEpicMiddleware, EpicMiddleware} from 'redux-observable';

import {rootEpic} from './epics/Epics';
import {rootReducer} from './reducers/Reducers';

import {IAction} from './actions/Actions';
import {startup} from './actions/startup/StartupActions';
import {defaultAppState, IAppState} from './state/AppState';
import {Application} from './components/Application';
import {BrowserRouter} from 'react-router-dom';

const epicMiddleware: EpicMiddleware<
  IAction<any>,
  IAction<any>,
  IAppState,
  any
> = createEpicMiddleware();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  defaultAppState(),
  composeEnhancers(applyMiddleware(epicMiddleware)),
);

epicMiddleware.run(rootEpic);

store.dispatch(startup());

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
