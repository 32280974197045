import firebase, {firestore} from 'firebase/app';
import {Epic, ofType} from 'redux-observable';
import {Subject, of, concat, from} from 'rxjs';
import {map, switchMap, tap, catchError, filter} from 'rxjs/operators';

import {log} from '../../utils/Log';
import {ActionType, IAction, noOpAction} from '../../actions/Actions';

import {IAppState} from '../../state/AppState';

import {
  IClientDocument,
  IFirestoreUser,
  IFirestoreAccount,
  IFirestoreRole,
} from '../../state/firestore/FirestoreInterfaces';
import {
  firestoreUserRequest,
  firestoreUserResponse,
  firestoreUserError,
  firestoreAccountsRequest,
  firestoreAccountsError,
  firestoreAccountsResponse,
  firestoreRolesRequest,
  firestoreRolesResponse,
  firestoreRolesError,
  firestoreUsersRequest,
  firestoreUsersResponse,
  firestoreUsersError,
} from '../../actions/firestore/FirestoreActions';

const db = () => firebase.firestore();

export const fetchAccountsEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.FETCH_FIRESTORE_ACCOUNTS),
    switchMap(() =>
      concat(
        of(firestoreAccountsRequest()),
        createAccountsObserver().pipe(
          map((querySnap) =>
            querySnap.docs.map((queryDoc) =>
              convertToClientFirebaseDoc<IFirestoreAccount>(queryDoc),
            ),
          ),
          map((accounts) => firestoreAccountsResponse(accounts)),
        ),
        of('').pipe(
          tap(() => log.debug('Accounts observer complete')),
          map(() => firestoreAccountsError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(firestoreAccountsError(err)))),
    ),
  );

const createAccountsObserver = () => {
  const subject = new Subject<firestore.QuerySnapshot>();
  db()
    .collection('service_accounts')
    .onSnapshot(subject);
  return subject;
};

export const fetchUserEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.FETCH_FIRESTORE_USER),
    map((action) => action.payload as string),
    switchMap((email) =>
      concat(
        of(firestoreUserRequest(email)),
        createUserObserver(email).pipe(
          map((docSnap) => convertToClientFirebaseDoc<IFirestoreUser>(docSnap)),
          map((user) => firestoreUserResponse(user)),
        ),
        of('').pipe(
          tap(() => log.debug('User observer complete')),
          map(() => firestoreUserError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(firestoreUserError(err)))),
    ),
  );

const createUserObserver = (email: string) => {
  const subject = new Subject<firestore.DocumentSnapshot>();
  db()
    .collection('users')
    .doc(email)
    .onSnapshot(subject);
  return subject;
};

export const fetchRolesEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.FETCH_FIRESTORE_ROLES),
    switchMap(() =>
      concat(
        of(firestoreRolesRequest()),
        createRolesObserver().pipe(
          map((querySnap) =>
            querySnap.docs.map((queryDoc) =>
              convertToClientFirebaseDoc<IFirestoreRole>(queryDoc),
            ),
          ),
          map((roles) => firestoreRolesResponse(roles)),
        ),
        of('').pipe(
          tap(() => log.debug('Roles observer complete')),
          map(() => firestoreRolesError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(firestoreRolesError(err)))),
    ),
  );

const createRolesObserver = () => {
  const subject = new Subject<firestore.QuerySnapshot>();
  db()
    .collection('account_roles')
    .onSnapshot(subject);
  return subject;
};

export const fetchUsersEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.FETCH_FIRESTORE_USERS),
    switchMap(() =>
      concat(
        of(firestoreUsersRequest()),
        createUsersObserver().pipe(
          map((querySnap) =>
            querySnap.docs.map((queryDoc) =>
              convertToClientFirebaseDoc<IFirestoreUser>(queryDoc),
            ),
          ),
          map((users) => firestoreUsersResponse(users)),
        ),
        of('').pipe(
          tap(() => log.debug('Users observer complete')),
          map(() => firestoreUsersError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(firestoreUsersError(err)))),
    ),
  );

const createUsersObserver = () => {
  const subject = new Subject<firestore.QuerySnapshot>();
  db()
    .collection('users')
    .onSnapshot(subject);
  return subject;
};

export const writeFirestoreUserEpic: Epic<
  IAction<IFirestoreUser>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FIRESTORE_WRITE_USER),
    map((action) => action.payload),
    switchMap((user) =>
      from(
        db()
          .collection('users')
          .doc(user.id)
          .set(
            {accountsRoles: user.accountsRoles, admin: user.admin},
            {
              merge: true,
            },
          ),
      ).pipe(
        filter(() => false),
        map(() => noOpAction()),
      ),
    ),
  );

export const deleteFirestoreUserEpic: Epic<
  IAction<string>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FIRESTORE_DELETE_USER),
    map((action) => action.payload),
    switchMap((email) =>
      from(
        db()
          .collection('users')
          .doc(email)
          .delete(),
      ).pipe(
        filter(() => false),
        map(() => noOpAction()),
      ),
    ),
  );

export const convertToClientFirebaseDoc = <T extends IClientDocument>(
  document: firestore.QueryDocumentSnapshot | firestore.DocumentSnapshot,
): T => {
  return {
    ...document.data(),
    id: document.id,
    path: document.ref.path,
  } as T;
};
