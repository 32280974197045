import React, {useState, useEffect} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import {AccountRoleIcon} from '../Common/AccountRoleIcon';
import {
  IFirestoreUser,
  IAdminServiceAccount,
} from '../../state/firestore/FirestoreInterfaces';

const useStyles = makeStyles((theme) => ({
  topList: {
    width: '100%',
  },
  categoryItem: {
    border: 1,
  },
  accountItem: {
    paddingLeft: theme.spacing(6),
    cursor: 'default',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  accounts: IAdminServiceAccount[];
  user: IFirestoreUser;
  onSelected: (accountName: string, accountId: string, roleId: string) => void;
}

export const AdminAccountList: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  const [open, setOpen] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    if (Object.keys(open).length === 0) {
      const storage = localStorage.getItem('adminOpen');
      if (storage !== null) {
        setOpen(JSON.parse(storage));
      }
    }
    localStorage.setItem('adminOpen', JSON.stringify(open));
  }, [open]);

  const categoryListItems: JSX.Element[] = [];
  let accountListItems: JSX.Element[] = [];

  const createCategoryItem = (account: IAdminServiceAccount, index: number) => {
    return (
      <ListItem
        button
        className={classes.categoryItem}
        key={`${account.service}_${index}`}
        onClick={() =>
          setOpen({
            ...open,
            [account.service]: open[account.service] !== true,
          })
        }>
        <ListItemIcon>
          <MenuIcon />
        </ListItemIcon>
        <ListItemText primary={account.service.toUpperCase()} />
        {open[account.service] !== true ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
    );
  };

  const createAccountItem = (account: IAdminServiceAccount, index: number) => {
    return (
      <ListItem
        button
        key={account.id}
        disableTouchRipple={true}
        className={classes.accountItem}>
        <ListItemText primary={account.name} />
        {account.roles
          .sort((a, b) => b.index - a.index)
          .map((role) => (
            <AccountRoleIcon
              key={role.id}
              accountName={account.name}
              accountId={account.id}
              roleId={role.id}
              roleName={role.name}
              selected={props.user.accountsRoles.includes(role.id)}
              onSelected={props.onSelected}
            />
          ))}
      </ListItem>
    );
  };

  props.accounts.forEach((account, index, arr) => {
    const prev: IAdminServiceAccount | undefined = arr[index - 1];
    if (!prev || prev.service !== account.service) {
      accountListItems = [];
      categoryListItems.push(createCategoryItem(account, index));
      categoryListItems.push(
        <Collapse
          key={`collapsable_${index}`}
          in={open[account.service] === true}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            {accountListItems}
          </List>
        </Collapse>,
      );
    }

    accountListItems.push(createAccountItem(account, index));
  });

  return <List className={classes.topList}>{categoryListItems}</List>;
};
