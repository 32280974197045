import firebase from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {AjaxRequest, AjaxResponse, ajax} from 'rxjs/ajax';
import {
  map,
  tap,
  filter,
  mergeMap,
  switchMap,
  catchError,
} from 'rxjs/operators';

import log from '../../utils/Log';
import {IAction, ActionType, noOpAction} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';

import {
  accountLoginRequest,
  accountLoginError,
  accountLoginResponse,
} from '../../actions/account/AccountActions';
import {from, of, concat} from 'rxjs';
import {getUser, getAuthDomain} from '../../selectors/auth/AuthSelectors';
import {IAccountLoginDetails} from '../../actions/account/AccountActions';

export const kibanaAccountLoginEpic: Epic<
  IAction<IAccountLoginDetails>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_KIBANA_LOGIN),
    switchMap((action) =>
      concat(
        of(accountLoginRequest(action.payload)),
        of(getUser(state$.value)).pipe(
          filter((user): user is firebase.User => user !== null),
          mergeMap((user) =>
            from(user.getIdToken()).pipe(
              mergeMap((token) =>
                ajax(
                  createGetKibanaConsoleUrlRequest(
                    action.payload.accountRole,
                    token,
                  ),
                ).pipe(
                  map((response) => getConsoleUrlFromResponse(response)),
                  mergeMap((url) =>
                    concat(
                      of(accountLoginResponse(url)),
                      of(url).pipe(
                        tap((url) => log.info('Kibana Url: ', url)),
                        tap((url) => window.open(url, '_blank')),
                        map(() => noOpAction()),
                      ),
                    ),
                  ),
                ),
              ),
            ),
          ),
          catchError((err) =>
            of(err).pipe(map((err) => accountLoginError(err))),
          ),
        ),
      ),
    ),
  );

const createGetKibanaConsoleUrlRequest = (
  role: string,
  token: string,
): AjaxRequest => ({
  url: `https://${getAuthDomain(
    window.location.hostname,
  )}/kibana?accountRole=${role}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'GET',
  responseType: 'text',
});

const getConsoleUrlFromResponse = (response: AjaxResponse): string => {
  if (response.response && typeof response.response === 'string') {
    return response.response;
  } else {
    throw new Error('Unexpected response format from kibana');
  }
};
