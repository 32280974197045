import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';
import {IFirestoreAccount} from '../../state/firestore/FirestoreInterfaces';
import {MenuItem} from '../../components/Routes';

import {
  getFirestoreUser,
  getAccounts,
  getAccountsError,
  getFirestoreUserError,
} from '../firestore/FirestoreSelectors';

export interface IServiceAccount extends IFirestoreAccount {
  roles: {
    id: string;
    name: string;
  }[];
}

export const getCurrentUserRoles = createSelector(
  getFirestoreUser,
  (user) => {
    if (user) {
      return user.accountsRoles || [];
    }
    return [];
  },
);

const getWelcomeScreenAccounts = createSelector(
  getCurrentUserRoles,
  getAccounts,
  (roles, serviceAccounts) => {
    if (serviceAccounts === null) {
      return [];
    }

    const accounts: {[key: string]: IServiceAccount} = {};
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      const accountName: string = role.substr(0, role.lastIndexOf('_'));
      const roleName: string = role.substr(role.lastIndexOf('_') + 1);

      const account = serviceAccounts.find((doc) => doc.id === accountName);
      if (account) {
        if (accounts[accountName] !== undefined) {
          accounts[accountName].roles.push({
            id: role,
            name: roleName,
          });
        } else {
          accounts[accountName] = {
            ...account,
            roles: [
              {
                id: role,
                name: roleName,
              },
            ],
          };
        }
      }
    }

    const accountsArray = Object.values(accounts);
    accountsArray.forEach(
      (account) =>
        (account.roles = account.roles.sort((a, b) =>
          a.name > b.name ? 1 : -1,
        )),
    );

    return accountsArray.sort((a, b) => {
      if (a.service === b.service) {
        if (a.category === b.category) {
          return a.index - b.index;
        } else {
          return a.category > b.category ? 1 : -1;
        }
      } else {
        return a.service > b.service ? 1 : -1;
      }
    });
  },
);

export const getAmazonScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'aws'),
);

export const getKubernetesScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'kubernetes'),
);

export const getKibanaScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'kibana'),
);

export const getGamesScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'gamesadmin'),
);

export const getSupportScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'support'),
);

export const getAmundsenScreenAccounts = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.filter((account) => account.service === 'amundsen'),
);

const getAmazonAccountsEnabled = createSelector(
  getAmazonScreenAccounts,
  (accounts) => accounts.length > 0,
);

const getOnCallAccountsEnabled = createSelector(
  getWelcomeScreenAccounts,
  (accounts) => accounts.length > 0, // Return true for on call as soon as we have the accounts response
);

const getKubernetesAccountsEnabled = createSelector(
  getKubernetesScreenAccounts,
  (accounts) => accounts.length > 0,
);

const getKibanaAccountsEnabled = createSelector(
  getKibanaScreenAccounts,
  (accounts) => accounts.length > 0,
);

const getGamesAccountsEnabled = createSelector(
  getGamesScreenAccounts,
  (accounts) => accounts.length > 0,
);

const getSupportAccountsEnabled = createSelector(
  getSupportScreenAccounts,
  (accounts) => accounts.length > 0,
);

const getAmundsenAccountsEnabled = createSelector(
  getAmundsenScreenAccounts,
  (accounts) => accounts.length > 0,
);

export const getMenuAccountsEnabled = (state: IAppState) => ({
  [MenuItem.ON_CALL]: getOnCallAccountsEnabled(state),
  [MenuItem.AWS]: getAmazonAccountsEnabled(state),
  [MenuItem.KUBERNETES]: getKubernetesAccountsEnabled(state),
  [MenuItem.KIBANA]: getKibanaAccountsEnabled(state),
  [MenuItem.AMUNDSEN]: getAmundsenAccountsEnabled(state),
  [MenuItem.GAMES]: getGamesAccountsEnabled(state),
  [MenuItem.SUPPORT]: getSupportAccountsEnabled(state),
});

export const getWelcomeScreenError = createSelector(
  getAccountsError,
  getFirestoreUserError,
  (accountError, userError) => {
    if (accountError !== null || userError !== null) {
      return accountError || userError;
    }
    return null;
  },
);
