import firebase from 'firebase/app';
import {Epic, ofType} from 'redux-observable';
import {Subject, of, from, concat} from 'rxjs';
import {
  map,
  switchMap,
  tap,
  mergeMap,
  filter,
  catchError,
} from 'rxjs/operators';

import {log} from '../../utils/Log';

import {ActionType, IAction} from '../../actions/Actions';

import {IAppState} from '../../state/AppState';
import {
  loginComplete,
  logOut,
  setIsAdmin,
} from '../../actions/auth/AuthActions';
import {
  fetchFirestoreAccounts,
  fetchFirestoreUser,
  firestoreUserError,
  firestoreAccountsError,
} from '../../actions/firestore/FirestoreActions';
import {getIsUserFromValidDomain} from '../../selectors/auth/AuthSelectors';

export const startupLoginEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    map(() => getAuthStateObservable()),
    switchMap((authStateObs) =>
      authStateObs.pipe(
        tap((user) =>
          log.info('[AuthStateObservable] User change event', user),
        ),
        mergeMap((user) =>
          concat(
            of(user).pipe(
              map((user) => (user !== null ? loginComplete(user) : logOut())),
            ),
            of(user).pipe(
              filter((user) => user !== null),
              filter((user) => throwIfUserNotValid(user)),
              map((user) => fetchFirestoreAccounts()),
              catchError((err) => of(firestoreAccountsError(err))),
            ),
            of(user).pipe(
              filter((user): user is firebase.User => user !== null),
              filter((user) => throwIfUserNotValid(user)),
              map((user) => fetchFirestoreUser(user.email as string)),
              catchError((err) => of(firestoreUserError(err))),
            ),
            of(user).pipe(
              filter((user) => user !== null),
              filter((user) => throwIfUserNotValid(user)),
              mergeMap((user) =>
                from((user as firebase.User).getIdTokenResult()).pipe(
                  map((result) => result.claims.admin === true),
                  map((isAdmin) => setIsAdmin(isAdmin)),
                ),
              ),
              catchError((err) => of(setIsAdmin(false))),
            ),
          ),
        ),
      ),
    ),
  );

const getAuthStateObservable = () => {
  const authState = new Subject<firebase.User | null>();
  log.debug('[onAuthStateChanged] register');
  firebase.auth().onAuthStateChanged(authState);

  return authState;
};

const throwIfUserNotValid = (user: firebase.User | null) => {
  if (user === null) {
    throw new Error('We have had a problem logging you in');
  } else if (user.email === null) {
    throw new Error("We don't seem to be able to access your email");
  } else if (user.emailVerified === false) {
    throw new Error('Your email address has not been verified');
  } else if (!getIsUserFromValidDomain(user.email)) {
    throw new Error(
      `Your email address '${user.email}' is not from a valid domain`,
    );
  } else {
    return true;
  }
};
