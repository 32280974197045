import {IAppState} from '../../state/AppState';
import {createSelector} from 'reselect';
import {getAccounts, getUsers, getRoles} from '../firestore/FirestoreSelectors';
import {IAdminServiceAccount} from '../../state/firestore/FirestoreInterfaces';
import {getUser} from '../auth/AuthSelectors';

export const getShouldLoadRoles = createSelector(
  getUser,
  getRoles,
  (state: IAppState) => state.serverApis.firestore.roles.isUpdating,
  (user, roles, isUpdating) =>
    user !== null && roles === null && isUpdating === false,
);

export const getShouldLoadUsers = createSelector(
  getUser,
  getUsers,
  (state: IAppState) => state.serverApis.firestore.users.isUpdating,
  (user, users, isUpdating) =>
    user !== null && users === null && isUpdating === false,
);

export const getAdminServiceAccounts = createSelector(
  getRoles,
  getAccounts,
  (roles, accounts) => {
    if (roles === null || accounts === null) {
      return null;
    }

    const serviceAccounts = accounts.map(
      (account) =>
        ({
          ...account,
          roles: roles.filter(
            (role) =>
              role.id.substring(0, role.id.lastIndexOf('_')) === account.id,
          ),
        } as IAdminServiceAccount),
    );
    return serviceAccounts;
  },
);
