import * as firebase from 'firebase/app';
import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import {Tooltip, Box} from '@material-ui/core';
import DarkModeIcon from '@material-ui/icons/Brightness4';
import LightModeIcon from '@material-ui/icons/Brightness5';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface IProps {
  isLoggingIn: boolean;
  title: string | null;
  user: firebase.User | null;
  onMenuSelected: () => void;
  isDark: boolean;
  onThemeToggle: () => void;
}

export const TitleBar: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const _onProfileClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const _onCloseMenu = () => {
    setAnchorEl(null);
  };

  const _signOut = () => {
    firebase.auth().signOut();
    _onCloseMenu();
  };

  return (
    <div>
      <AppBar color="default" position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            disabled={props.user === null}
            onClick={props.onMenuSelected}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.user !== null
              ? props.title
              : props.isLoggingIn === true
              ? ''
              : 'Authenticator'}
          </Typography>
          {props.user !== null && (
            <React.Fragment>
              {props.isDark && (
                <IconButton
                  className={classes.menuButton}
                  onClick={props.onThemeToggle}>
                  <LightModeIcon />
                </IconButton>
              )}
              {!props.isDark && (
                <IconButton
                  className={classes.menuButton}
                  onClick={props.onThemeToggle}>
                  <DarkModeIcon />
                </IconButton>
              )}
              <Tooltip
                title={
                  <React.Fragment>
                    <Box>
                      <Typography variant="subtitle2">
                        Google Account
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {props.user.displayName}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {props.user.email}
                      </Typography>
                    </Box>
                  </React.Fragment>
                }>
                <IconButton onClick={_onProfileClick}>
                  <Avatar
                    alt={props.user.displayName || undefined}
                    src={props.user.photoURL || undefined}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={_onCloseMenu}>
                <MenuItem onClick={_signOut}>Sign Out</MenuItem>
              </Menu>
            </React.Fragment>
          )}

          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};
