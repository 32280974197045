import {IAppState} from '../../state/AppState';

export const getIsLoggingIn = (state: IAppState) => state.auth.isLogginIn;
export const getIsAdmin = (state: IAppState) => state.auth.isAdmin;
export const getUser = (state: IAppState) => state.auth.user;

//TODO - Consolidate with code in cloud functions in a central sub project
export const getValidUserDomains = () => ['bagelcode.com', 'gingercode.com'];
export const getValidUserUserEmails = () => [
  'farren.bagelcode@gmail.com',
  'apps@cashworldslots.com',
  'justin@jumiken.com',
];

//TODO - Consolidate with code in cloud functions in a central sub project
export const getIsUserFromValidDomain = (email: string) =>
  getValidUserDomains().find((domain) => email.endsWith(`@${domain}`)) !==
    undefined ||
  getValidUserUserEmails().find((e) => email === e) !== undefined;

/**
 * Hosted domain `hd` property to use for oauth redirect
 * @param domain
 */
export const getHostedDomain = (domain: string) => {
  domain = extractDomain(domain);
  const isValid =
    getValidUserDomains().find((validDomain) => validDomain === domain) !==
    undefined;
  if (!isValid) {
    // Accommodate for localhost being an alias for bagelcode.com
    return 'bagelcode.com';
  } else {
    return domain;
  }
};

const extractDomain = (url: string) => {
  return url.replace(/^(?:https?:\/\/)?(?:[^/]+\.)?([^./]+\.[^./]+).*$/, '$1');
};

/**
 * The auth name to use for the firebase configuration
 * @param hostName
 */
export const getAuthDomain = (hostName: string) =>
  hostName === 'localhost' ? 'auth.bagelcode.com' : hostName;
