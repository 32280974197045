import {Reducer} from 'redux';
import {
  undefinedInReducerError,
  handleServerRequest,
  handleServerResponse,
  handleServerError,
} from '../Reducers';
import {IAction, ActionType} from '../../actions/Actions';
import {IFirestoreServerApi} from '../../state/AppState';

export const firestoreReducer: Reducer<IFirestoreServerApi, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.FIRESTORE_ACCOUNTS_REQUEST:
      return {
        ...state,
        accounts: handleServerRequest(state.accounts, action),
      };
    case ActionType.FIRESTORE_ACCOUNTS_RESPONSE:
      return {
        ...state,
        accounts: handleServerResponse(state.accounts, action),
      };
    case ActionType.FIRESTORE_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: handleServerError(state.accounts, action),
      };

    case ActionType.FIRESTORE_USER_REQUEST:
      return {
        ...state,
        user: handleServerRequest(state.user, action),
      };
    case ActionType.FIRESTORE_USER_RESPONSE:
      return {
        ...state,
        user: handleServerResponse(state.user, action),
      };
    case ActionType.FIRESTORE_USER_ERROR:
      return {
        ...state,
        user: handleServerError(state.user, action),
      };

    case ActionType.FIRESTORE_ROLES_REQUEST:
      return {
        ...state,
        roles: handleServerRequest(state.roles, action),
      };
    case ActionType.FIRESTORE_ROLES_RESPONSE:
      return {
        ...state,
        roles: handleServerResponse(state.roles, action),
      };
    case ActionType.FIRESTORE_ROLES_ERROR:
      return {
        ...state,
        roles: handleServerError(state.roles, action),
      };

    case ActionType.FIRESTORE_USERS_REQUEST:
      return {
        ...state,
        users: handleServerRequest(state.users, action),
      };
    case ActionType.FIRESTORE_USERS_RESPONSE:
      return {
        ...state,
        users: handleServerResponse(state.users, action),
      };
    case ActionType.FIRESTORE_USERS_ERROR:
      return {
        ...state,
        users: handleServerError(state.users, action),
      };

    case ActionType.PAGERDUTY_WEBHOOK_REQUEST:
      return {
        ...state,
        pagerDutyWebHook: handleServerRequest(state.pagerDutyWebHook, action),
      };
    case ActionType.PAGERDUTY_WEBHOOK_RESPONSE:
      return {
        ...state,
        pagerDutyWebHook: handleServerResponse(state.pagerDutyWebHook, action),
      };
    case ActionType.PAGERDUTY_WEBHOOK_ERROR:
      return {
        ...state,
        pagerDutyWebHook: handleServerError(state.pagerDutyWebHook, action),
      };
  }
  return state;
};
