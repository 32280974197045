import {IAction, ActionType, processErrorToString} from '../Actions';
import {
  IPagerDutyStatusResponse,
  IPagerDutyWebHookResponse,
  IPagerDutyIncidentRequest,
  IPagerDutyIncidentResponse,
} from '../../state/pagerduty/PagerDutyInterfaces';

export const fetchPagerDutyWebhook = (): IAction<null> => ({
  type: ActionType.FETCH_PAGERDUTY_WEBHOOK,
  payload: null,
});

export const pagerDutyWebhookRequest = (): IAction<null> => ({
  type: ActionType.PAGERDUTY_WEBHOOK_REQUEST,
  payload: null,
});

export const pagerDutyWebhookResponse = (
  url: IPagerDutyWebHookResponse,
): IAction<IPagerDutyWebHookResponse> => ({
  type: ActionType.PAGERDUTY_WEBHOOK_RESPONSE,
  payload: url,
});

export const pagerDutyWebhookError = (err: any): IAction<string> => {
  return {
    type: ActionType.PAGERDUTY_WEBHOOK_ERROR,
    payload: processErrorToString(err),
  };
};

export const fetchPagerDutyStatus = (): IAction<null> => ({
  type: ActionType.FETCH_PAGERDUTY_STATUS,
  payload: null,
});

export const pagerDutyStatusRequest = (): IAction<null> => ({
  type: ActionType.PAGERDUTY_STATUS_REQUEST,
  payload: null,
});

export const pagerDutyStatusResponse = (
  response: IPagerDutyStatusResponse,
): IAction<IPagerDutyStatusResponse> => ({
  type: ActionType.PAGERDUTY_STATUS_RESPONSE,
  payload: response,
});

export const pagerDutyStatusError = (err: any): IAction<string> => {
  return {
    type: ActionType.PAGERDUTY_STATUS_ERROR,
    payload: processErrorToString(err),
  };
};

export const pagerDutyWriteIncidentRequest = (
  request: IPagerDutyIncidentRequest,
): IAction<IPagerDutyIncidentRequest> => ({
  type: ActionType.PAGERDUTY_WRITE_INCIDENT_REQUEST,
  payload: request,
});

export const pagerDutyWriteIncidentResponse = (
  response: IPagerDutyIncidentResponse,
): IAction<IPagerDutyIncidentResponse> => ({
  type: ActionType.PAGERDUTY_WRITE_INCIDENT_RESPONSE,
  payload: response,
});

export const pagerDutyWriteIncidentError = (err: any): IAction<string> => {
  return {
    type: ActionType.PAGERDUTY_WRITE_INCIDENT_ERROR,
    payload: processErrorToString(err),
  };
};
