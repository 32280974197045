import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import WriteIcon from '@material-ui/icons/Edit';
import CodeIcon from '@material-ui/icons/Code';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import ReadIcon from '@material-ui/icons/Visibility';

import {capFirstCharacter} from '../../utils/TextUtils';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  accountId: string;
  accountName: string;
  roleName: string;
  roleId: string;
  selected?: boolean;
  onSelected: (accountName: string, accountId: string, roleId: string) => void;
}

export const AccountRoleIcon: React.FC<IProps> = (props) => {
  const classes = useStyles({});

  const getIcon = (roleName: string, selected: boolean) => {
    switch (roleName) {
      case 'administrator':
      case 'master':
        return <WriteIcon color={selected ? 'primary' : 'disabled'} />;
      case 'developer':
      case 'develop':
        return <CodeIcon color={selected ? 'primary' : 'disabled'} />;
      case 'operations':
        return <BusinessIcon color={selected ? 'primary' : 'disabled'} />;
      case 'customer service':
      case 'cs':
        return <PeopleIcon color={selected ? 'primary' : 'disabled'} />;
      default:
        return <ReadIcon color={selected ? 'primary' : 'disabled'} />;
    }
  };

  return (
    <IconButton
      className={classes.button}
      size="small"
      key={props.roleId}
      onClick={() =>
        props.onSelected(props.accountName, props.accountId, props.roleId)
      }>
      <Tooltip title={capFirstCharacter(props.roleName)}>
        {getIcon(props.roleName.toLowerCase(), props.selected !== false)}
      </Tooltip>
    </IconButton>
  );
};
