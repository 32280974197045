import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import AlarmIcon from '@material-ui/icons/Alarm';
import TableChartIcon from '@material-ui/icons/TableChart';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import {AwsIcon, KubernetesIcon, KibanaIcon} from './MenuIcons';
import {MenuItem} from '../Routes';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

interface IProps {
  open: boolean;
  selectedItem: string | null;
  enabledAccounts: {[key in MenuItem]?: boolean};
  isAdministrator: boolean;
  onItemSelected: (item: MenuItem) => void;
  onClose: () => void;
}

const menuItems = [
  {id: MenuItem.AWS, icon: <AwsIcon />},
  {id: MenuItem.KUBERNETES, icon: <KubernetesIcon />},
  {id: MenuItem.KIBANA, icon: <KibanaIcon />},
  {id: MenuItem.AMUNDSEN, icon: <TableChartIcon />},
  {id: MenuItem.ON_CALL, icon: <AlarmIcon />},
  {id: MenuItem.GAMES, icon: <SportsEsportsIcon />},
  {id: MenuItem.SUPPORT, icon: <ContactSupportOutlinedIcon />},
];

export const Menu = (props: IProps) => {
  const classes = useStyles({});

  const createMenuItem = (
    item: MenuItem,
    icon: JSX.Element,
    disabled?: boolean,
  ) => {
    if (disabled === undefined) {
      disabled = !(props.enabledAccounts[item] === true);
    }
    if (!disabled) {
      return (
        <ListItem
          button
          selected={item === props.selectedItem}
          key={item}
          disabled={disabled}
          onClick={() => props.onItemSelected(item)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      );
    } else {
      return undefined;
    }
  };

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <List>
        <ListItem key="title">
          <ListItemText primary="Bagelcode Dashboard" />
        </ListItem>
      </List>
      <Divider />
      <List>{menuItems.map((item) => createMenuItem(item.id, item.icon))}</List>

      <Divider />
      {props.isAdministrator && (
        <List>
          {createMenuItem(MenuItem.ADMINISTRATION, <SettingsIcon />, false)}
        </List>
      )}
    </div>
  );
  return (
    <Drawer open={props.open} onClose={props.onClose}>
      {sideList()}
    </Drawer>
  );
};
