import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '35%',
    left: '50%',
    position: 'absolute',
    width: 400,
    marginLeft: -200,
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  emailLabel: {
    margin: theme.spacing(2, 0, 2),
  },
  button: {
    margin: theme.spacing(1, 2, 1, 0),
  },
}));

interface IProps {
  email: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirmed: (email: string) => void;
}

export const DeleteUser: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});

  const _onConfirmed = (email: string) => {
    props.onConfirmed(email);
  };

  return (
    <div>
      <Modal
        aria-labelledby="delete-user-modal-title"
        aria-describedby="delete-user-modal-description"
        open={props.isOpen}>
        <div className={classes.modal}>
          <Typography variant="h6">
            Are you sure you want to delete the user?
          </Typography>
          <Typography className={classes.emailLabel}>{props.email}</Typography>
          <Button
            className={classes.button}
            aria-label="cancel"
            color="default"
            variant="contained"
            onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            className={classes.button}
            aria-label="delete"
            color="primary"
            variant="contained"
            onClick={() => _onConfirmed(props.email)}>
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};
