import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {getIsUserFromValidDomain} from '../../selectors/auth/AuthSelectors';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '35%',
    left: '50%',
    position: 'absolute',
    width: 400,
    marginLeft: -200,
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  textField: {
    width: '100%',
    margin: theme.spacing(2, 2, 1, 0),
  },
}));

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirmed: (email: string) => void;
}

export const AddUser: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  const defaultEmail = '@bagelcode.com';
  const [email, setEmail] = useState<string>(defaultEmail);

  const _onEmailChanged = (event: any) => {
    setEmail(event.target.value);
  };

  const _isValidEmail = (email: string) => {
    // Doesn't contain @ with atleast one character before it.
    if (email.indexOf('@') < 1) {
      return false;
    }

    return getIsUserFromValidDomain(email);
  };

  const _onConfirmed = (email: string) => {
    setEmail(defaultEmail);
    props.onConfirmed(email);
  };

  return (
    <div>
      <Modal
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
        open={props.isOpen}>
        <div className={classes.modal}>
          <Typography variant="h6">
            Please enter the email address of the new user below:
          </Typography>
          <TextField
            required
            id="user-add-email-required"
            label="Required"
            value={email}
            onChange={_onEmailChanged}
            className={classes.textField}
            margin="normal"
            color="primary"
            variant="outlined"
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              aria-label="cancel"
              color="default"
              variant="contained"
              onClick={props.onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.button}
              aria-label="add"
              color="primary"
              variant="contained"
              disabled={!_isValidEmail(email)}
              onClick={() => _onConfirmed(email)}>
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
