import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {AdminAccountList} from './AdminAccountList';
import {
  IFirestoreUser,
  IAdminServiceAccount,
} from '../../state/firestore/FirestoreInterfaces';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  adminCheckbox: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  listContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  accountName: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  user: IFirestoreUser;
  accounts: IAdminServiceAccount[];
  onUpdateUser: (user: IFirestoreUser) => void;
}

export const UserForm: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});

  const _changeAdminStatus = () => {
    props.onUpdateUser({
      ...props.user,
      admin: props.user.admin != true,
    });
  };

  const _toggleRoleInUsersAccount = (
    accountName: string,
    accountId: string,
    roleId: string,
  ) => {
    const newRoles = [...props.user.accountsRoles];
    const index = newRoles.indexOf(roleId);
    if (index > -1) {
      newRoles.splice(index, 1);
    } else {
      const existingRoleForAccountIndex = newRoles.findIndex(
        (r) => r.indexOf(accountId) === 0,
      );
      if (existingRoleForAccountIndex > -1) {
        newRoles.splice(existingRoleForAccountIndex, 1);
      }
      newRoles.push(roleId);
    }
    props.onUpdateUser({
      ...props.user,
      accountsRoles: newRoles,
    });
  };

  return (
    <div className={classes.formContainer}>
      <FormControlLabel
        className={classes.adminCheckbox}
        control={
          <Checkbox
            checked={props.user.admin}
            onChange={_changeAdminStatus}
            color="primary"
          />
        }
        label="Administrator"
      />
      <div className={classes.listContainer}>
        <AdminAccountList
          accounts={props.accounts}
          user={props.user}
          onSelected={_toggleRoleInUsersAccount}
        />
      </div>
    </div>
  );
};
