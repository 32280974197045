import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '40%',
    left: '50%',
    position: 'absolute',
    width: 400,
    marginLeft: -200,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: 18,
  },
}));

interface IProps {
  open: boolean;
  label: string;
}

export const ModalProgress: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  return (
    <Modal
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
      open={props.open}>
      <div className={classes.modal}>
        <Typography variant="h6" className={classes.child}>
          {props.label}
        </Typography>
        <CircularProgress color="primary" className={classes.child} />
      </div>
    </Modal>
  );
};
