import {IAction, ActionType} from '../Actions';

export const setDarkMode = (isDark: boolean): IAction<boolean> => ({
  type: ActionType.SET_DARK_MODE,
  payload: isDark,
});

export const setV2GamesAdminFunctions = (
  enabled: boolean,
): IAction<boolean> => ({
  type: ActionType.SET_V2_GAMES_ADMIN_FUNCTIONS,
  payload: enabled,
});
