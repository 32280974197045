import React, {useState} from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';

import firebase from 'firebase/app';

import {useSelector, useDispatch} from 'react-redux';

import {TitleBar} from './Title/TitleBar';
import {LoginScreen} from './Login/LoginScreen';
import {AdminScreen} from './Admin/AdminScreen';
import {WelcomeScreen} from './Welcome/WelcomeScreen';
import {Footer} from './Footer/Footer';
import {Menu} from './Menu/Menu';
import {ThemeProvider} from './ThemeProvider';

import {makeStyles} from '@material-ui/core/styles';
import {CssBaseline} from '@material-ui/core';

import {setDarkMode} from '../actions/localSettings/LocalSettingsActions';
import {getIsDarkMode} from '../selectors/localSettings/LocalSettingsSelectors';
import {
  getIsAdmin,
  getIsLoggingIn,
  getUser,
} from '../selectors/auth/AuthSelectors';
import {getMenuAccountsEnabled} from '../selectors/welcome/WelcomeSelectors';
import './Application.scss';
import {MenuItem, getMenuItemFromPath, menuRoutes} from './Routes';

interface IStateProps {
  isAdmin: boolean;
  isDark: boolean;
  isLoggingIn: boolean;
  user: firebase.User | null;
  menuAccountsEnabled: {[key in MenuItem]?: boolean};
}

interface IDispatchProps {
  setDarkMode: (isDark: boolean) => void;
}

export const Application: React.FC = (props) => {
  const dispatch = useDispatch();

  return (
    <ApplicationView
      {...{
        ...props,
        isAdmin: useSelector(getIsAdmin),
        isDark: useSelector(getIsDarkMode),
        isLoggingIn: useSelector(getIsLoggingIn),
        user: useSelector(getUser),
        menuAccountsEnabled: useSelector(getMenuAccountsEnabled),
        setDarkMode: (isDark: boolean) => dispatch(setDarkMode(isDark)),
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

export const ApplicationView: React.FC<IStateProps & IDispatchProps> = (
  props,
) => {
  const history = useHistory();
  const classes = useStyles();

  const [showMenu, setShowMenu] = useState(false);

  const _toggleMenu = () => {
    setShowMenu(showMenu != true);
  };

  const _menuItemSelected = async (item: MenuItem) => {
    history.push({
      pathname: menuRoutes[item],
      search: window.location.search,
    });
    setShowMenu(false);
  };

  const renderedScreen =
    props.isLoggingIn === true ? null : props.user === null ? (
      <LoginScreen />
    ) : (
      <Switch>
        <Route path={menuRoutes[MenuItem.ADMINISTRATION]}>
          <AdminScreen />
        </Route>
        <Route path={menuRoutes[MenuItem.AWS]}>
          <WelcomeScreen screen={MenuItem.AWS} />
        </Route>
        <Route path={menuRoutes[MenuItem.KUBERNETES]}>
          <WelcomeScreen screen={MenuItem.KUBERNETES} />
        </Route>
        <Route path={menuRoutes[MenuItem.KIBANA]}>
          <WelcomeScreen screen={MenuItem.KIBANA} />
        </Route>
        <Route path={menuRoutes[MenuItem.ON_CALL]}>
          <WelcomeScreen screen={MenuItem.ON_CALL} />
        </Route>
        <Route path={menuRoutes[MenuItem.SUPPORT]}>
          <WelcomeScreen screen={MenuItem.SUPPORT} />
        </Route>
        <Route path={menuRoutes[MenuItem.GAMES]}>
          <WelcomeScreen screen={MenuItem.GAMES} />
        </Route>
        <Route path="/">
          <Redirect to={menuRoutes[MenuItem.GAMES]} />
        </Route>
      </Switch>
    );
  return (
    <ThemeProvider isDark={props.isDark}>
      <CssBaseline />
      <div className={classes.root}>
        <TitleBar
          isLoggingIn={props.isLoggingIn}
          title={getMenuItemFromPath(window.location.pathname)}
          user={props.user}
          onMenuSelected={_toggleMenu}
          isDark={props.isDark}
          onThemeToggle={() => props.setDarkMode(props.isDark != true)}
        />
        {renderedScreen}
        <Footer />
        <Menu
          enabledAccounts={props.menuAccountsEnabled}
          isAdministrator={props.isAdmin}
          selectedItem={getMenuItemFromPath(window.location.pathname)}
          open={showMenu}
          onItemSelected={_menuItemSelected}
          onClose={_toggleMenu}></Menu>
      </div>
    </ThemeProvider>
  );
};
