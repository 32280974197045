import firebase from 'firebase/app';
import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {Card, CardHeader, IconButton, useMediaQuery} from '@material-ui/core';

import {UserForm} from './UserForm';
import {AddUser} from './AddUser';
import {DeleteUser} from './DeleteUser';
import {
  IFirestoreUser,
  IAdminServiceAccount,
} from '../../state/firestore/FirestoreInterfaces';
import {
  getShouldLoadUsers,
  getAdminServiceAccounts,
  getShouldLoadRoles,
} from '../../selectors/admin/AdminSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {getUsers} from '../../selectors/firestore/FirestoreSelectors';
import {
  fetchFirestoreRoles,
  fetchFirestoreUsers,
  writeFirestoreUser,
  deleteFirestoreUser,
} from '../../actions/firestore/FirestoreActions';
import {getIsAdmin, getUser} from '../../selectors/auth/AuthSelectors';

interface IComponentProps {}

interface IStateProps {
  user: firebase.User | null;
  isAdmin: boolean;
  shouldLoadUsers: boolean;
  shouldLoadRoles: boolean;
  users: IFirestoreUser[] | null;
  accounts: IAdminServiceAccount[] | null;
}

interface IDispatchProps {
  loadRoles: () => void;
  loadUsers: () => void;
  writeUser: (user: IFirestoreUser) => void;
  deleteUser: (email: string) => void;
}

export const AdminScreen: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const user = useSelector(getUser);
  const shouldLoadRoles = useSelector(getShouldLoadRoles);
  const shouldLoadUsers = useSelector(getShouldLoadUsers);
  const users = useSelector(getUsers);
  const accounts = useSelector(getAdminServiceAccounts);
  return isAdmin ? (
    <AdminScreenView
      {...{
        ...props,
        user,
        isAdmin,
        shouldLoadRoles,
        shouldLoadUsers,
        users,
        accounts,
        loadRoles: () => dispatch(fetchFirestoreRoles()),
        loadUsers: () => dispatch(fetchFirestoreUsers()),
        writeUser: (user) => dispatch(writeFirestoreUser(user)),
        deleteUser: (email) => dispatch(deleteFirestoreUser(email)),
      }}
    />
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignContent: 'center',
    },
    justifyContent: 'center',
  },
  listContainer: {
    width: 310,
    minWidth: 310,
    height: 'calc(100vh - 150px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '40vh',
    },
    overflow: 'auto',
  },
  formContainer: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '95%',
    height: 'calc(100vh - 150px)',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(0),
      height: 'auto',
    },
  },
  selectedUserEmail: {
    fontSize: 20,
    marginTop: -5,
    // textAlign: 'left',
  },
  fab: {
    marginLeft: 242,
    bottom: 70,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      right: 90,
      top: 'calc(40vh + 45px)',
    },
    [theme.breakpoints.down('xs')]: {
      right: 80,
      top: 'calc(40vh + 45px)',
    },
  },
  modal: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addIcon: {
    color: theme.palette.background.default,
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const AdminScreenView: React.FC<
  IComponentProps & IStateProps & IDispatchProps
> = (props) => {
  const classes = useStyles({});
  const isSmallScreen = useMediaQuery((theme) =>
    (theme as any).breakpoints.down('sm'),
  );
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | null>(
    null,
  );
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  const {shouldLoadRoles, shouldLoadUsers, loadRoles, loadUsers} = props;
  useEffect(() => {
    if (shouldLoadRoles) {
      loadRoles();
    }
  }, [shouldLoadRoles, loadRoles]);

  useEffect(() => {
    if (shouldLoadUsers) {
      loadUsers();
    }
  }, [shouldLoadUsers, loadUsers]);

  // Write users back to the DB
  const _onUserChange = (user: IFirestoreUser) => {
    props.writeUser(user);
  };

  // Delete the user from the DB and select a different user if possible.
  const _onDeleteUserConfirmed = (email: string) => {
    setDeleteUserModalOpen(false);
    props.deleteUser(email);
    if (props.users !== null && props.users.length > 0) {
      setSelectedUserEmail(props.users[0].id);
    } else {
      setSelectedUserEmail(null);
    }
  };

  // Add the user from the DB and set as selected.
  const _onAddUserConfirmed = (email: string) => {
    if (
      props.users &&
      props.users.find((user) => user.id === email) === undefined
    ) {
      // Only write new user if the user does not exist, else just select the current user
      props.writeUser({
        id: email,
        path: `users/${email}`,
        accountsRoles: [],
        admin: false,
      });
    }
    setSelectedUserEmail(email);
    setAddUserModalOpen(false);
  };

  if (props.users === null || props.accounts === null) {
    return <div />;
  }

  if (selectedUserEmail === null) {
    setSelectedUserEmail(props.user ? props.user.email : null);
    return <div />;
  }

  const selectedUser = props.users.find(
    (user) => user.id === selectedUserEmail,
  );

  // Render accounts
  const userList = props.users.map((user, index) => {
    return (
      <ListItem
        button
        key={`${user.id}_${index}`}
        selected={user.id === selectedUserEmail}
        onClick={() => setSelectedUserEmail(user.id)}>
        <ListItemText primary={user.id} />
        <IconButton
          className={classes.button}
          size="small"
          aria-label="delete"
          color="primary"
          onClick={() => setDeleteUserModalOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </ListItem>
    );
  });
  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.container}>
        <Paper className={classes.listContainer}>
          <List dense={true}>{userList}</List>
          <Fab
            color="primary"
            aria-label="add"
            size={isSmallScreen ? 'small' : 'large'}
            className={classes.fab}
            onClick={() => setAddUserModalOpen(true)}>
            <AddIcon className={classes.addIcon} />
          </Fab>
        </Paper>
        <Card className={classes.formContainer} title={selectedUserEmail}>
          <CardHeader
            className={classes.selectedUserEmail}
            titleTypographyProps={{className: classes.selectedUserEmail}}
            title={selectedUserEmail}
          />
          {selectedUser && (
            <UserForm
              user={selectedUser}
              accounts={props.accounts}
              onUpdateUser={_onUserChange}
            />
          )}
        </Card>
        <AddUser
          isOpen={addUserModalOpen}
          onCancel={() => setAddUserModalOpen(false)}
          onConfirmed={_onAddUserConfirmed}></AddUser>
        <DeleteUser
          email={selectedUserEmail}
          isOpen={deleteUserModalOpen}
          onCancel={() => setDeleteUserModalOpen(false)}
          onConfirmed={_onDeleteUserConfirmed}></DeleteUser>
      </div>
    </Container>
  );
};
