import {IAppState} from '../../state/AppState';
import {createSelector} from 'reselect';
import {
  IPagerDutyAccountModel,
  IPagerDutyAccount,
  IPagerDutyUser,
  IPagerDutyIncidentWithAssignments,
  IPagerDutyIncident,
  PagerDutyStatus,
} from '../../state/pagerduty/PagerDutyInterfaces';
import {getUser} from '../auth/AuthSelectors';

const getTier1Accounts = (state: IAppState) => [
  {name: 'Club Vegas Slots (Technical)', id: 'PC5ZD4K'},
  {name: 'Club Vegas Slots (CRM)', id: 'PYCL91H'},
  {name: 'Jackpotjoy Slots (Hybrid)', id: 'PYWOCSX'},
  {name: 'Jackpotjoy Slots (Legacy)', id: 'PZ8JNRR'},
  {name: 'Jackpotjoy Slots (CRM)', id: 'PC5B6F1'},
  {name: 'Starspins Slots', id: 'P1SPVAQ'},
  {name: 'Bingo Lane', id: 'PS82SRE'},
  {name: 'Data', id: 'PZ01C2W'},
];

const getPagerDutyStatus = (state: IAppState) =>
  state.serverApis.pagerDuty.status.response;

export const getIsPagerDutyStatusUpdating = (state: IAppState) =>
  state.serverApis.pagerDuty.status.isUpdating;

export const getPagerDutyStatusError = (state: IAppState) =>
  state.serverApis.pagerDuty.status.error;

export const getShouldRequestPagerDuty = createSelector(
  getUser,
  getIsPagerDutyStatusUpdating,
  getPagerDutyStatus,
  (user, isUpdating, status) => user !== null && !isUpdating && status === null,
);

export const getPagerDutyAccountsAndData = createSelector(
  getPagerDutyStatus,
  (accounts): IPagerDutyAccountModel[] => {
    if (accounts) {
      return accounts
        .sort((account1, account2) =>
          account1.service.name > account2.service.name ? 1 : -1,
        )
        .map((account) => {
          const service = account.service;
          const users = getUsersForService(account);
          const incidents = getIncidentsForService(account, users);
          const status = getServiceStatus(incidents);
          return {
            service,
            status,
            incidents,
            users,
          };
        });
    }
    return [];
  },
);

export const getPagerDutyTier1AccountsAndData = createSelector(
  getPagerDutyAccountsAndData,
  getTier1Accounts,
  (accounts, tier1Accounts): IPagerDutyAccountModel[] =>
    tier1Accounts
      .map((tier1Account) =>
        accounts.find((account) => account.service.id === tier1Account.id),
      )
      .filter(
        (account): account is IPagerDutyAccountModel => account !== undefined,
      ),
);
export const getPagerDutyNonTier1AccountsAndData = createSelector(
  getPagerDutyAccountsAndData,
  getTier1Accounts,
  (accounts, tier1Accounts): IPagerDutyAccountModel[] =>
    accounts.filter(
      (account) =>
        !tier1Accounts.some(
          (tier1Account) => tier1Account.id === account.service.id,
        ),
    ),
);

const getUsersForService = (service: IPagerDutyAccount): IPagerDutyUser[] =>
  service.oncalls
    .sort((oc1, oc2) => oc1.escalation_level - oc2.escalation_level)
    .map((oncall) => service.users.find((user) => user.id === oncall.user.id))
    .filter((user): user is IPagerDutyUser => user !== undefined)
    .filter(deDupefilter);

const getIncidentsForService = (
  service: IPagerDutyAccount,
  users: IPagerDutyUser[],
): IPagerDutyIncidentWithAssignments[] =>
  service.incidents.map((incident) => ({
    incident: incident,
    assignments: getAssignedUsers(incident, users),
  }));

const getAssignedUsers = (
  incident: IPagerDutyIncident,
  users: IPagerDutyUser[],
) =>
  incident.assignments
    .map((assignment) =>
      users.find((user) => user.id === assignment.assignee.id),
    )
    .filter((user): user is IPagerDutyUser => user !== undefined)
    .filter(deDupefilter);

const getServiceStatus = (
  incidents: IPagerDutyIncidentWithAssignments[],
): PagerDutyStatus => {
  let status: PagerDutyStatus = 'resolved';
  incidents.forEach((incident) => {
    if (incident.incident.status === 'triggered') {
      status = 'triggered';
    } else if (
      status !== 'triggered' &&
      incident.incident.status === 'acknowledged'
    ) {
      status = 'acknowledged';
    }
  });

  return status;
};

export const getWriteIncidentResponse = (state: IAppState) =>
  state.serverApis.pagerDuty.writeIncident.response;
export const getWriteIncidentError = (state: IAppState) =>
  state.serverApis.pagerDuty.writeIncident.error;

const deDupefilter = (
  user: IPagerDutyUser,
  index: number,
  self: IPagerDutyUser[],
) => self.findIndex((u) => u.id === user.id) === index;

export const getColorForStatus = (status: PagerDutyStatus) => {
  switch (status) {
    case 'triggered':
      return 'red';
    case 'acknowledged':
      return 'orange';
    default:
      return 'green';
  }
};
