import React, {useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import List from '@material-ui/core/List';

import {
  fetchPagerDutyStatus,
  fetchPagerDutyWebhook,
  pagerDutyWriteIncidentRequest,
} from '../../actions/pagerduty/PagerDutyActions';
import {
  IPagerDutyAccountModel,
  IPagerDutyIncidentResponse,
} from '../../state/pagerduty/PagerDutyInterfaces';
import {
  getWriteIncidentResponse,
  getWriteIncidentError,
  getPagerDutyStatusError,
  getPagerDutyTier1AccountsAndData,
  getPagerDutyNonTier1AccountsAndData,
  getShouldRequestPagerDuty,
} from '../../selectors/pagerduty/PagerDutySelectors';

import {PagerDutyHeader} from './List/PagerDutyHeader';
import {PagerDutyDetails} from './List/PagerDutyDetails';
import {ReportIncidentOverlay} from './ReportIncidentOverlay';
import {Typography, Grid, Paper, CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accountPaper: {
    marginBottom: theme.spacing(2),
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  accountsLoading: {
    margin: theme.spacing(4),
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  topList: {
    width: '100%',
  },
  errorMessage: {
    width: '100%',
  },
}));

interface IComponentProps {}

interface IStateProps {
  tier1Accounts: IPagerDutyAccountModel[];
  nonTier1Accounts: IPagerDutyAccountModel[];
  accountsError: string | null;
  writeIncidentResponse: IPagerDutyIncidentResponse | null;
  writeIncidentError: string | null;
}

interface IDispatchProps {
  onPagerDutyIncidentReport: (
    service: string,
    title: string,
    details: string,
  ) => void;
}

export const PagerDutyAccountList: React.FC<IComponentProps> = (
  props: IComponentProps,
) => {
  const dispatch = useDispatch();

  const shouldRequest = useSelector(getShouldRequestPagerDuty);
  useEffect(() => {
    if (shouldRequest) {
      dispatch(fetchPagerDutyStatus());
      dispatch(fetchPagerDutyWebhook());
    }
  }, [shouldRequest, dispatch]);

  return (
    <PagerDutyAccountListView
      {...{
        ...props,
        tier1Accounts: useSelector(getPagerDutyTier1AccountsAndData),
        nonTier1Accounts: useSelector(getPagerDutyNonTier1AccountsAndData),
        accountsError: useSelector(getPagerDutyStatusError),
        writeIncidentResponse: useSelector(getWriteIncidentResponse),
        writeIncidentError: useSelector(getWriteIncidentError),
        onPagerDutyIncidentReport: (
          service: string,
          title: string,
          details: string,
        ) => {
          dispatch(pagerDutyWriteIncidentRequest({service, title, details}));
        },
      }}
    />
  );
};

export const PagerDutyAccountListView: React.FC<
  IComponentProps & IStateProps & IDispatchProps
> = (props: IComponentProps & IStateProps & IDispatchProps) => {
  const classes = useStyles({} as any);
  const [open, setOpen] = useState<{[key: string]: boolean}>({});
  const [serviceForIncident, setServiceForIncident] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [showWriteIncidenSnackbar, setShowWriteIncidentSnackbar] = useState(
    false,
  );
  const {writeIncidentResponse, writeIncidentError} = props;

  useEffect(() => {
    if (writeIncidentError !== null || writeIncidentResponse !== null) {
      setServiceForIncident(null);
      setShowWriteIncidentSnackbar(true);
    }
  }, [writeIncidentResponse, writeIncidentError]);

  const _onOpenHeading = (accountId: string) => {
    setOpen({
      ...open,
      [accountId]: open[accountId] !== true,
    });
  };

  if (props.accountsError !== null) {
    return (
      <Fragment>
        <Grid className={classes.errorMessage} spacing={2} container>
          <Grid item>
            <Typography align="center">
              We have encountered an error connecting to the Pager Duty API.
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center">[{props.accountsError}]</Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  const createAccountListItems = (accounts: IPagerDutyAccountModel[]) => {
    const categoryListItems: JSX.Element[] = [];
    accounts.forEach((account, index, arr) => {
      categoryListItems.push(
        <PagerDutyHeader
          key={`${account.service.id}_${index}`}
          account={account}
          open={open[account.service.id]}
          onOpen={_onOpenHeading}
        />,
      );
      categoryListItems.push(
        <PagerDutyDetails
          key={`collapsable_${index}`}
          open={open[account.service.id] === true}
          account={account}
          onCreateIncident={setServiceForIncident}
        />,
      );
    });
    return categoryListItems;
  };

  return (
    <Fragment>
      {props.tier1Accounts.length === 0 && (
        <Paper className={classes.accountsLoading}>
          <CircularProgress />
        </Paper>
      )}
      {props.tier1Accounts.length > 0 && (
        <Paper className={classes.accountPaper}>
          <List key={'tier1Accounts'} className={classes.topList}>
            {createAccountListItems(props.tier1Accounts)}
          </List>
        </Paper>
      )}
      {props.nonTier1Accounts.length > 0 && (
        <Paper className={classes.accountPaper}>
          <List key={'tier2Accounts'} className={classes.topList}>
            {createAccountListItems(props.nonTier1Accounts)}
          </List>
        </Paper>
      )}
      <ReportIncidentOverlay
        service={serviceForIncident}
        onCancel={() => setServiceForIncident(null)}
        onConfirmed={props.onPagerDutyIncidentReport}
      />

      <Snackbar
        open={showWriteIncidenSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowWriteIncidentSnackbar(false)}>
        <Alert severity={writeIncidentError !== null ? 'error' : 'success'}>
          {writeIncidentError !== null
            ? `Failed to submit incident: ${writeIncidentError}`
            : `Incident successfully submitted`}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
