import React, {useState, useEffect, useRef} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import {capFirstCharacter} from '../../utils/TextUtils';
import {AccountRoleIcon} from '../Common/AccountRoleIcon';

import {IServiceAccount} from '../../selectors/welcome/WelcomeSelectors';

const useStyles = makeStyles((theme) => ({
  topList: {
    width: '100%',
  },
  categoryItem: {
    border: 1,
  },
  accountItem: {
    paddingLeft: theme.spacing(4),
    cursor: 'default',
  },
  nested: {},
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  accounts: IServiceAccount[];
  service: string;
  onSelected: (accountName: string, accountId: string, roleId: string) => void;
}

export const AccountList: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<{[key: string]: boolean}>({});
  const prevAccountsRef = useRef<IServiceAccount[]>();

  const categoryListItems: JSX.Element[] = [];
  let accountListItems: JSX.Element[] = [];

  useEffect(() => {
    const hasOneCategory = (accounts: IServiceAccount[]) => {
      let cat: string | null = null;
      for (let i = 0; i < accounts.length; i++) {
        if (accounts[i].category !== cat && cat !== null) {
          return false;
        } else {
          cat = accounts[i].category;
        }
      }
      return true;
    };
    if (
      props.accounts.length > 0 &&
      prevAccountsRef.current !== props.accounts
    ) {
      prevAccountsRef.current = props.accounts;
      if (hasOneCategory(props.accounts)) {
        setOpen({...open, [props.accounts[0].category]: true});
      }
    }
  }, [open, props.accounts]);

  const createCategoryItem = (account: IServiceAccount, index: number) => {
    return (
      <ListItem
        button
        className={classes.categoryItem}
        key={`${account.category}_${index}`}
        onClick={() =>
          setOpen({
            ...open,
            [account.category]: open[account.category] !== true,
          })
        }>
        <ListItemIcon>
          <MenuIcon />
        </ListItemIcon>
        <ListItemText primary={formatCategoryTitle(account.category)} />
        {open[account.category] !== true ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
    );
  };

  const createAccountItem = (account: IServiceAccount, index: number) => {
    return (
      <ListItem
        button
        disableTouchRipple={true}
        key={`${account.name}_${index}`}
        className={classes.accountItem}>
        <ListItemText primary={account.name} />
        {account.roles.map((role) => (
          <AccountRoleIcon
            key={role.id}
            accountName={account.name}
            accountId={account.id}
            roleId={role.id}
            roleName={role.name}
            onSelected={props.onSelected}
          />
        ))}
      </ListItem>
    );
  };

  props.accounts.forEach((account, index, arr) => {
    const prev: IServiceAccount | undefined = arr[index - 1];
    if (!prev || prev.category !== account.category) {
      accountListItems = [];
      categoryListItems.push(createCategoryItem(account, index));
      categoryListItems.push(
        <Collapse
          key={`collapsable_${index}`}
          in={open[account.category] === true}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            {accountListItems}
          </List>
        </Collapse>,
      );
    }

    accountListItems.push(createAccountItem(account, index));
  });

  return <List className={classes.topList}>{categoryListItems}</List>;
};

const formatCategoryTitle = (category: string): string =>
  category.length > 4
    ? capFirstCharacter(category)
    : category.toLocaleUpperCase();
