import {Reducer} from 'redux';
import {IAction} from '../actions/Actions';
import {defaultAppState, IAppState, IApiData} from '../state/AppState';

import {accountReducer} from './account/AccountReducer';
import {firestoreReducer} from './firestore/FirestoreReducer';
import {pagerDutyReducer} from './pagerduty/PagerDutyReducer';
import {authReducer} from './auth/AuthReducer';
import {localSettingsReducer} from './localSettings/LocalSettingsReducer';

export const rootReducer: Reducer<IAppState, IAction<any>> = (
  state: IAppState | undefined,
  action: IAction<any>,
): IAppState => {
  if (!state) {
    return defaultAppState();
  }
  return {
    auth: authReducer(state.auth, action),
    localSettings: localSettingsReducer(state.localSettings, action),
    serverApis: {
      account: accountReducer(state.serverApis.account, action),
      firestore: firestoreReducer(state.serverApis.firestore, action),
      pagerDuty: pagerDutyReducer(state.serverApis.pagerDuty, action),
    },
  };
};

export const undefinedInReducerError = new Error(
  'Passing undefined to a reducer is not allowed',
);

/**
 * Generic server request handler for updating an IApiData instance
 */
export const handleServerRequest = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isUpdating: true,
    request: processPayload(action.payload),
  };
};

/**
 * Generic server response handler for updating an IApiData instance
 */
export const handleServerResponse = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isUpdating: false,
    lastUpdated: new Date(),
    response: processPayload(action.payload),
  };
};

/**
 * Generic server error handler for updating an IApiData instance
 */
export const handleServerError = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: processPayload(action.payload),
    isUpdating: false,
    lastUpdated: new Date(),
    response: null,
  };
};

const processPayload = (payload: any): any => {
  const responseType: string = typeof payload;
  let response: any;
  if (
    responseType === 'string' ||
    responseType === 'number' ||
    responseType === 'boolean' ||
    responseType === 'undefined' ||
    payload === null
  ) {
    response = payload;
  } else if (Array.isArray(payload)) {
    response = [...payload];
  } else {
    response = {...payload};
  }
  return response;
};
