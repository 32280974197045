import firebase from 'firebase/app';

import {Epic, ofType} from 'redux-observable';
import {IAction, ActionType} from '../../actions/Actions';
import {IAppState} from '../../state/AppState';
import {
  map,
  tap,
  filter,
  mergeMap,
  switchMap,
  catchError,
} from 'rxjs/operators';

import {
  accountLoginRequest,
  accountLoginError,
  accountLoginResponse,
} from '../../actions/account/AccountActions';
import {from, of, concat} from 'rxjs';
import {getUser} from '../../selectors/auth/AuthSelectors';
import {IAccountLoginDetails} from '../../actions/account/AccountActions';

export const supportLoginEpic: Epic<
  IAction<IAccountLoginDetails>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_SUPPORT_LOGIN),
    switchMap((action) =>
      concat(
        of(accountLoginRequest(action.payload)),
        of(getUser(state$.value)).pipe(
          filter((user): user is firebase.User => user !== null),
          mergeMap((user) =>
            from(user.getIdToken()).pipe(
              map((token) =>
                createSupportAdminUrl(action.payload.accountRole, token),
              ),
              tap((url) => window.open(url, '_blank')),
              map((url) => accountLoginResponse(url)),
            ),
          ),
          catchError((err) =>
            of(err).pipe(map((err) => accountLoginError(err))),
          ),
        ),
      ),
    ),
  );

const createSupportAdminUrl = (role: string, token: string): string => {
  // support_jackpotjoy_admin
  const path = role
    .substring(role.indexOf('_') + 1, role.lastIndexOf('_'))
    .replace(/_/g, '-');
  return `https://support-admin.bagelcode.com/admin/${path}?token=${token}`;
};
